export const LifeRingIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9995 22.1538C17.6074 22.1538 22.1534 17.6078 22.1534 12C22.1534 6.39216 17.6074 1.84613 11.9995 1.84613C6.39173 1.84613 1.8457 6.39216 1.8457 12C1.8457 17.6078 6.39173 22.1538 11.9995 22.1538Z"
        fill="white"
      />
      <path
        d="M8.30667 1.84613H15.1042L12.8381 7.51013H10.5728L8.30667 1.84613ZM8.60021 21.8603H15.3977L13.1316 16.3218H10.8664L8.60021 21.8603ZM21.8593 15.3987V8.60121L16.3208 10.8674V13.1326L21.8593 15.3987ZM2.13867 15.3987V8.60121L7.67713 10.8674V13.1326L2.13867 15.3987Z"
        fill="#FF564F"
      />
      <path
        d="M11.9993 2.72768C6.87835 2.72768 2.72701 6.87902 2.72701 12C2.72701 17.121 6.87835 21.2723 11.9993 21.2723C17.1203 21.2723 21.2716 17.121 21.2716 12C21.2716 6.87902 17.1203 2.72768 11.9993 2.72768ZM0.880859 12C0.880859 5.85942 5.85875 0.881531 11.9993 0.881531C18.1399 0.881531 23.1178 5.85942 23.1178 12C23.1178 18.1406 18.1399 23.1185 11.9993 23.1185C5.85875 23.1185 0.880859 18.1406 0.880859 12ZM11.9807 6.54572C12.7062 6.53107 13.4274 6.66133 14.1019 6.92885C14.7765 7.19638 15.3909 7.5958 15.9092 8.10372C16.4275 8.61165 16.8392 9.21787 17.1203 9.8869C17.4014 10.5559 17.5462 11.2743 17.5462 12C17.5462 12.7257 17.4014 13.4441 17.1203 14.1131C16.8392 14.7821 16.4275 15.3883 15.9092 15.8963C15.3909 16.4042 14.7765 16.8036 14.1019 17.0711C13.4274 17.3387 12.7062 17.4689 11.9807 17.4543C10.5532 17.4255 9.19388 16.8381 8.19452 15.8184C7.19516 14.7987 6.6354 13.4278 6.6354 12C6.6354 10.5722 7.19516 9.20131 8.19452 8.18158C9.19388 7.16184 10.5532 6.57453 11.9807 6.54572ZM13.4214 8.64497C12.9751 8.46798 12.498 8.38181 12.018 8.3915C11.0735 8.41056 10.1742 8.79912 9.51305 9.47376C8.85189 10.1484 8.48156 11.0554 8.48156 12C8.48156 12.9446 8.85189 13.8516 9.51305 14.5262C10.1742 15.2009 11.0735 15.5894 12.018 15.6085C12.498 15.6182 12.9751 15.532 13.4214 15.355C13.8676 15.178 14.2741 14.9138 14.617 14.5777C14.9599 14.2417 15.2323 13.8406 15.4183 13.398C15.6042 12.9554 15.7 12.4801 15.7 12C15.7 11.5199 15.6042 11.0446 15.4183 10.602C15.2323 10.1594 14.9599 9.7583 14.617 9.42226C14.2741 9.08622 13.8676 8.82197 13.4214 8.64497Z"
        fill="#4D7AFF"
      />
    </svg>
  );
};

export default LifeRingIcon;
