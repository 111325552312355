/* eslint-disable @typescript-eslint/no-empty-function */
import Countly from "countly-sdk-web";
import * as changeCase from "change-case";
const MODULES_APP_VERSION = "1.0";

class AnalyticsHelper {
  private static instance: AnalyticsHelper;
  private countly: any;

  static getInstance() {
    try {
      if (!AnalyticsHelper.instance) {
        AnalyticsHelper.instance = new AnalyticsHelper();
      }

      if (!AnalyticsHelper.instance.countly) {
        AnalyticsHelper.instance.initCountly();
      }
      return AnalyticsHelper.instance;
    } catch (error) {
      return {
        trackEvent: () => {},
        trackEventV2: () => {},
        trackUser: () => {},
      };
    }
  }

  private initCountly = () => {
    this.countly = Countly || {};
    this.countly.q = Countly.q || [];
    this.countly.app_key = process.env.REACT_APP_ANALYTICS_API_KEY;
    this.countly.url = process.env.REACT_APP_ANALYTICS_API_URL;
    this.countly.app_version = MODULES_APP_VERSION;

    console.log(`Initialising Countly at ${this.countly.url}`);

    this.countly.q.push(["track_sessions"]);
    this.countly.q.push(["track_pageview"]);
    this.countly.q.push(["track_clicks"]);
    this.countly.q.push(["track_scrolls"]);
    this.countly.q.push(["track_errors"]);
    this.countly.q.push(["track_links"]);
    this.countly.remote_config = false;
    this.countly.init();
  };

  trackPageView = (pageName: string) => {
    this.countly.q.push(["track_pageview", pageName]);
  };

  setUserProperty = (key: string, value: string) => {
    this.countly.q.push(["userData.set", key, value]);
  };

  trackEvent = (
    context: string,
    object: string,
    action: string,
    extraInfo?: { [index: string]: string | number | boolean }
  ) => {
    const eventName = `on${changeCase.pascalCase(
      object
    )}${changeCase.pascalCase(action)}`;

    // eslint-disable-next-line no-sparse-arrays
    this.countly.q.push([
      "add_event",
      {
        key: eventName,
        segmentation: { ...extraInfo, origin: changeCase.pascalCase(context) },
      },
      ,
    ]);
  };

  trackUser = (userInfo: { [index: string]: string | number }) => {
    this.countly.q.push([
      "user_details",
      {
        custom: {
          ...userInfo,
        },
      },
    ]);
  };
}

export default AnalyticsHelper;
