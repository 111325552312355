import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Ritual } from "../../types/Ritual";
import { getRituals } from "../../store/actions/actions";
import styled from "styled-components";
import Breadcrumbs, { Crumb } from "../../components/Breadcrumbs";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import theme from "../../styling/theme";
import AnalyticsHelper from "../../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../../services/analytics/analyticsObjectType";
import { AnalyticsAction } from "../../services/analytics/analyticsAction";
import { RitualDetailsSection } from "@groov/ui";
import { useEmbedTokenV2 } from "../../hooks/useEmbedTokenV2";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import { FilterType } from "powerbi-models";
import { EmbedToken } from "../../types/EmbedToken";
import "../../styling/styles/report.css";
import {
  NO_RITUALS,
  RITUAL_CHECK_IN_RESULTS,
} from "../../test/constants/ritualReporting";

interface ParamTypes {
  ritualId: string;
  teamId: string;
  companyId: string;
}

const RootDiv = styled.div`
  padding-bottom: 30px;
  width: 60vw;
`;

const RitualReporting = () => {
  const dispatch = useDispatch();
  const { ritualId, teamId, companyId } = useParams<ParamTypes>();
  const rituals: Ritual[] = useSelector(
    (state: RootStateOrAny) => state.rituals?.data?.rituals
  );
  const reportId = "200750d2-2a96-4909-a2c9-17cc9e9efe7e";
  const reportSection = "ReportSectionc0340c7bc3a001ef0492";
  useEmbedTokenV2(reportId);
  const embedToken: EmbedToken = useSelector(
    (state: RootStateOrAny) => state.powerBI?.embedTokens[reportId]
  );

  useEffect(() => {
    dispatch(getRituals(teamId));
  }, []);

  const ritual = rituals?.find((r) => r.id === ritualId);

  if (!ritual || !embedToken) {
    return <div data-testid={NO_RITUALS}></div>;
  }

  const crumbs = [
    { text: "Team home page", href: `/${companyId}/${teamId}/rituals` },
    {
      text: "Team reporting",
      href: `/${companyId}/${teamId}/rituals/team-reporting`,
    },
    { text: "Ritual reporting" },
  ];

  const onBreadcrumbLinkClicked = (crumb: Crumb): void => {
    AnalyticsHelper.getInstance().trackEvent(
      "RitualReportingPage",
      AnalyticsObjectType.breadcrumb,
      AnalyticsAction.clicked,
      { link: crumb.href!, text: crumb.text }
    );
  };

  const filterProps = {
    $schema: "http://powerbi.com/product/schema#basic",
    target: { table: "Ritual Team Overview", column: "ritual_id" },
    filterType: FilterType.Basic,
    operator: ritualId ? "In" : "All",
    values: [ritualId ? ritualId.toString() : ""],
  };

  const embedConfig = {
    type: "report",
    id: embedToken.embedUrl[0].reportId,
    embedUrl: embedToken.embedUrl[0].embedUrl,
    accessToken: embedToken.accessToken,
    tokenType: models.TokenType.Embed,
    pageName: reportSection,
    filters: [filterProps],
    settings: {
      panes: {
        filters: { expanded: false, visible: false },
        pageNavigation: { visible: false },
      },
      background: models.BackgroundType.Transparent,
    },
  };

  return (
    <RootDiv>
      <Breadcrumbs
        separator={
          <ArrowForward style={{ height: "12px", verticalAlign: "middle" }} />
        }
        crumbs={crumbs}
        onLinkClicked={onBreadcrumbLinkClicked}
      />
      <div
        style={{
          marginLeft: theme.spacing(2),
          marginTop: theme.spacing(3),
        }}
      >
        <RitualDetailsSection ritual={ritual} />
      </div>
      <div
        style={{
          marginTop: theme.spacing(3),
        }}
        data-testid={RITUAL_CHECK_IN_RESULTS}
      >
        <PowerBIEmbed embedConfig={embedConfig} cssClassName={"report-style"} />
      </div>
    </RootDiv>
  );
};

export default RitualReporting;
