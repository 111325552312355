import { RitualAction } from "../../types/RitualAction";
import { RitualTrigger } from "../../types/RitualTrigger";
import {
  GET_RITUAL_ACTIONS_SUCCESS,
  GET_RITUAL_TRIGGERS_SUCCESS,
} from "../actions/actions";

export interface RitualSuggestionsState {
  actions: RitualAction[];
  actionsLoaded: boolean;
  triggers: RitualTrigger[];
  triggersLoaded: boolean;
}

const initialState: RitualSuggestionsState = {
  actions: [],
  actionsLoaded: false,
  triggers: [],
  triggersLoaded: false,
};

const RitualSuggestionsReducer = (
  state: RitualSuggestionsState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_RITUAL_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action?.payload,
        actionsLoaded: true,
      };
    case GET_RITUAL_TRIGGERS_SUCCESS:
      return {
        ...state,
        triggers: action?.payload,
        triggersLoaded: true,
      };
    default:
      return state;
  }
};

export default RitualSuggestionsReducer;
