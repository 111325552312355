const Person = () => {
  return (
    <svg
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5514 11.8182C18.1295 11.8182 19.8069 12.9008 20.6087 14.6801C20.9851 15.5153 21.1168 16.3602 21.1084 17.0995L21.092 17.4619C21.0377 18.0115 20.5482 18.4131 19.9985 18.3588C19.4882 18.3084 19.1055 17.8827 19.0969 17.3821L19.1034 17.2351C19.1303 16.7323 19.0512 16.0919 18.7852 15.5017C18.3296 14.4906 17.4339 13.8784 15.8017 13.8224L15.5514 13.8182H6.45477C4.66039 13.8182 3.69652 14.44 3.21896 15.5013C2.98701 16.0167 2.8978 16.5708 2.89737 17.0383L2.90423 17.2602C2.95931 17.8137 2.55836 18.3037 2.00882 18.3587C1.49853 18.4097 1.03954 18.0676 0.932576 17.5785L0.910661 17.4217C0.909552 17.4065 0.908167 17.3847 0.905399 17.3412C0.862382 16.547 0.977892 15.6077 1.39512 14.6805C2.16631 12.9668 3.75104 11.8993 6.17175 11.8226L6.45477 11.8182H15.5514ZM11.0002 0C14.0629 0 16.5457 2.48278 16.5457 5.54545C16.5457 8.60812 14.0629 11.0909 11.0002 11.0909C7.93755 11.0909 5.45477 8.60812 5.45477 5.54545C5.45477 2.48278 7.93755 0 11.0002 0ZM11.0002 2C9.04212 2 7.45477 3.58735 7.45477 5.54545C7.45477 7.50356 9.04212 9.09091 11.0002 9.09091C12.9583 9.09091 14.5457 7.50356 14.5457 5.54545C14.5457 3.58735 12.9583 2 11.0002 2Z"
        fill="#A5ABB7"
      />
    </svg>
  );
};

export default Person;
