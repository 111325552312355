export const colors = {
  white: "#FFFFFF",
  whisperWhite: "#FBFBFD",
  floralWhite: "#FFFAED",
  darkGrey: "#25272B",
  slateGrey: "#5F6368",
  slateGrey2: "#787C81",
  gray1: "#bcc1c5",
  green: "#23e094",
  green2: "#23e095",
  lightGreen: "#b4fadd",
  mysticGrey: "#EDF0F5",
  royalBlue: "#456DE6",
  royalBlueHover: "rgba(69, 109, 230,0.04)",
  orange: "#ff554f",
  redWarn: "#ff7d78",
  snow: "#fbfbfd",
  black: "#000000",
  silverSand: "#BDC1C5",
  whisperWhiteDark: "#f6f6f6",
  taupeGrey: "#898989",
  groovGrey: {
    5: "#f8fcff",
    20: "#E6EBF3",
    40: "#A5ABB7",
    50: "#6A707F",
    60: "#565E74",
    100: "#22262e",
  },
  groovBlue: {
    5: "#CCE9FF",
    10: "#99D0FF",
    90: "#3F97FF",
    100: "#006AFF",
    110: "#0051DB",
  },
  groovRed: {
    5: "#FDE5D8",
    10: "#FBC5B2",
    90: "#E7746A",
    100: "#D73C3C",
    110: "#B82B38",
  },
  groovGreen: {
    5: "#CDF9CE",
    10: "#9EF3A8",
    90: "#40B766",
    100: "#50E092", // ?? not in pallete //#0D7442
    110: "#0D7442",
  },
  groovMustard: {
    5: "#F9F4D1",
    10: "#F3E9A6",
    90: "#B8A54B",
    100: "#8A751D",
    110: "#766115",
  },
  groovMint: {
    5: "#E8FEF0",
    10: "#D2FDE6",
    90: "#BBFBDE",
    100: "#8CF4D9",
    110: "#46AFA8",
  },
  groovOrange: {
    5: "#FBECD7",
    10: "#E8B186",
    90: "#D28D62",
    100: "#B55D35",
    110: "#9B4326",
  },
  groovMauve: {
    5: "#F9EDF5",
    10: "#DCBDD7",
    90: "#BA99B7",
    100: "#8C6B8C",
    110: "#744E78",
  },
  groovNeutral: {
    70: "#4d5468",
  },
  groovPurple: {
    5: "#E5DDFF",
    10: "#AF9AFF",
    90: "#9981FF",
    100: "#7558FF",
    110: "#5840DB",
  },
};
