function LockIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 16 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>icons / ic-lock</title>
      <g
        id="Vitality---account-activation-web"
        stroke="none"
        strokeWidth="0.5"
        fill="none"
        fillRule="evenodd"
      >
        <g id="icons-/-ic-lock" transform="translate(-4.000000, -1.000000)">
          <g>
            <rect id="container" x="0" y="0" width="24" height="24"></rect>
            <path
              d="M12,1 C14.6887547,1 16.8818181,3.12230671 16.9953805,5.78311038 L17,6 L17.0006084,8.75511684 C18.8291847,10.2212497 20,12.4738956 20,15 C20,19.418278 16.418278,23 12,23 C7.581722,23 4,19.418278 4,15 C4,12.4734375 5.17123994,10.2204328 7.00038632,8.75431938 L7,6 C7,3.23857625 9.23857625,1 12,1 Z M12,9 C8.6862915,9 6,11.6862915 6,15 C6,18.3137085 8.6862915,21 12,21 C15.3137085,21 18,18.3137085 18,15 C18,11.6862915 15.3137085,9 12,9 Z M12,12 C13.1045695,12 14,12.8954305 14,14 C14,14.7398375 13.5982846,15.3858493 13.0010775,15.7318119 L13,17 C13,17.5522847 12.5522847,18 12,18 C11.4871642,18 11.0644928,17.6139598 11.0067277,17.1166211 L11,17 L10.9999275,15.7323937 C10.4021661,15.3865739 10,14.7402524 10,14 C10,12.8954305 10.8954305,12 12,12 Z M12,3 C10.4023191,3 9.09633912,4.24891996 9.00509269,5.82372721 L9,6 L9.0000295,7.58150511 C9.92645293,7.20650275 10.9391108,7 12,7 C13.0612698,7 14.0742738,7.20665097 15.0009677,7.58190884 L15,6 C15,4.34314575 13.6568543,3 12,3 Z"
              id="fill-color"
              fill="#000000"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default LockIcon;
