import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { updateClientAccessToken } from "../store/actions/actions";
import store from "../store/reducers/rootReducers";

const getClientAccessToken = () => store.getState().auth.clientAccessToken;

export const unauthenticatedRequestInterceptor = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  let accessToken = getClientAccessToken();
  const isUnauthenticatedRequest = !accessToken;
  if (isUnauthenticatedRequest) {
    try {
      const res = await getAppClientAccessToken();
      accessToken = res.accessToken;
      store.dispatch(updateClientAccessToken(res.accessToken));
    } catch (error) {
      return Promise.reject(new Error("Failed to get app client access token"));
    }
  }

  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${accessToken}`,
  };

  return config;
};

export const getNewAccessTokenOnTokenExpired = async (error: AxiosError) => {
  const originalConfig = error.config;

  if (
    error.response?.status === 401 ||
    error.response?.data.message === "Unauthorized"
  ) {
    const res = await getAppClientAccessToken();
    store.dispatch(updateClientAccessToken(res.accessToken));

    originalConfig.headers["Authorization"] = `Bearer ${res.accessToken}`;
    // use a new instance without refresh token interceptor to avoid retrying loop
    return axios(originalConfig);
  }

  return Promise.reject(error);
};

const getAppClientAccessToken = async (): Promise<{
  accessToken: string;
  expiry: number;
}> => {
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
  params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET || "");
  params.append("grant_type", "client_credentials");
  params.append("scope", "registration content");

  const result = await axios({
    baseURL: process.env.REACT_APP_IDENTITY_SERVICE_URL || "",
    url: "/connect/token",
    method: "POST",
    data: params,
  });

  return {
    accessToken: result.data.access_token,
    expiry: result.data.expires_in,
  };
};
