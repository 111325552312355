import { put, call } from "redux-saga/effects";
import {
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAILURE,
} from "../actions/actions";
import { companyApi, companyByOrgIdApi } from "../../services/api";
import { ToasterUtils } from "../../components";

export function* company(action) {
  try {
    const response = yield call(companyApi, action.payload);
    if (response) {
      yield put({
        type: GET_COMPANY_BY_ID_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error) {
    yield* getCompanyId(action);
  }
}

function* getCompanyId(action) {
  try {
    const response = yield call(companyByOrgIdApi, action.payload);
    if (response.data.total === 1) {
      const redirectUrl = location.href.replace(
        action.payload.id,
        response.data.companies[0].id
      );
      window.location.replace(redirectUrl);
    } else {
      throw response;
    }
  } catch (error) {
    ToasterUtils.error(error.response?.data.message);
    yield put({
      type: GET_COMPANY_BY_ID_FAILURE,
      payload: error.response?.data,
    });
  }
}
