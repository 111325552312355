import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
const COMPANY_API_KEY = process.env.REACT_APP_COMPANY_API_KEY;

export const ApiRequest = async (config) => {
  const headers = { "MTM-API-KEY": COMPANY_API_KEY };
  const newConfig = {
    baseURL: BASE_URL,
    headers,
    ...config,
  };
  return axios(newConfig).then((res) => {
    return res;
  });
};
