import { colors } from "../../styling/styles/colors";

interface Props {
  color?: string;
}
export const ChartIcon: React.FC<Props> = ({
  color = colors.groovBlue[100],
}) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1C2 0.447715 1.55228 0 1 0C0.447715 0 0 0.447715 0 1V19.0062C0 19.5585 0.447715 20.0062 1 20.0062H19.0247C19.577 20.0062 20.0247 19.5585 20.0247 19.0062C20.0247 18.454 19.577 18.0062 19.0247 18.0062H2V1ZM4 10.5047V17H7V10.5047C7 9.66832 6.32843 9 5.5 9C4.6658 9 4 9.67369 4 10.5047ZM9 17V3.4931C9 2.66848 9.6658 2 10.5 2C11.3284 2 12 2.66389 12 3.4931V17H9ZM14 6.49555V17H17V6.49555C17 5.67955 16.3284 5 15.5 5C14.6658 5 14 5.66958 14 6.49555Z"
        fill={color}
      />
    </svg>
  );
};

export default ChartIcon;
