export const TeamPageTestId = {
  TeamNameHeader: "TeamNameHeader",
  CreateANewRitualButton: "CreateANewRitualButton",
  RitualTileHeader: "RitualTileHeader",
  EditTeamInfoMenuButton: "EditTeamInfoMenuButton",
  EditOrRemoveRitualMenuButton: "EditOrRemoveRitualMenuButton",
  EditTeamMemberMenuButton: "EditTeamMemberMenuButton",
  EditTeamInfoLink: "EditTeamInfoLink",
  AddTeamMemberLink: "AddTeamMemberLink",
  EditRitualLink: "EditRitualLink",
  RemoveRitualLink: "RemoveRitualLink",
  ViewTeamReporting: "ViewTeamReporting",
  RitualComponent: "RitualComponent",
};
