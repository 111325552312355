import { EmbedToken } from "../../types/EmbedToken";
import {
  GET_EMBED_TOKEN_BEGIN,
  GET_EMBED_TOKEN_FAILURE,
  GET_EMBED_TOKEN_SUCCESS,
} from "../actions/powerBIActions";

export interface PowerBIState {
  embedTokens: {
    [reportId: string]: EmbedToken;
  };
  loading: boolean;
  error: string;
}

const initialState: PowerBIState = {
  embedTokens: {},
  loading: false,
  error: "",
};

const PowerBIReducer = (state: PowerBIState = initialState, action: any) => {
  switch (action.type) {
    case GET_EMBED_TOKEN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_EMBED_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        embedTokens: { [action.payload.embedUrl[0].reportId]: action.payload },
      };
    case GET_EMBED_TOKEN_FAILURE:
      return {
        loading: false,
        embedTokens: {},
        error: action.payload,
      };
    default:
      return state;
  }
};

export default PowerBIReducer;
