import styled from "styled-components";

import { Grid, useTheme } from "@material-ui/core";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";

import Breadcrumbs, { Crumb } from "../../components/Breadcrumbs";
import AnalyticsHelper from "../../services/analytics/analyticsHelper";
import { useParams } from "react-router-dom";
import { AnalyticsObjectType } from "../../services/analytics/analyticsObjectType";
import { AnalyticsAction } from "../../services/analytics/analyticsAction";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTeamRitualsOverview,
  getTeamRitualsReport,
} from "../../store/actions/ritualsReportActions";
import { RitualsReport } from "../../types/RitualsReport";
import { Company } from "../../types/Company";
import { ToasterUtils } from "../../components";
import { TeamOverview } from "./TeamOverview";
import { TeamReport } from "./TeamReport";

const RootDiv = styled.div`
  width: 60vw;
`;

interface ParamTypes {
  companyId: string;
  teamId: string;
}

const PAGE_NAME = "TeamReportingPage";

export const TeamReporting = () => {
  const theme = useTheme();
  const { companyId, teamId } = useParams<ParamTypes>();
  const dispatch = useDispatch();
  const ritualsReport: RitualsReport = useSelector(
    (state: RootStateOrAny) => state.ritualsReport
  );
  const company: Company = useSelector(
    (state: RootStateOrAny) => state.company
  );

  useEffect(() => {
    if (company.orgId) {
      dispatch(getTeamRitualsReport(company.orgId, teamId));
      dispatch(getTeamRitualsOverview(company.orgId, teamId));
    } else {
      ToasterUtils.error("Organisation ID is missing");
    }
  }, []);

  const crumbs = [
    { text: "Team home page", href: `/${companyId}/${teamId}/rituals` },
    { text: "Team reporting" },
  ];

  const onBreadcrumbLinkClicked = (crumb: Crumb): void => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    AnalyticsHelper.getInstance().trackEvent(
      PAGE_NAME,
      AnalyticsObjectType.breadcrumb,
      AnalyticsAction.clicked,
      { link: crumb.href!, text: crumb.text }
    );
  };

  return (
    <RootDiv>
      <Breadcrumbs
        separator={<ArrowForward style={{ height: "12px" }} />}
        crumbs={crumbs}
        onLinkClicked={onBreadcrumbLinkClicked}
      />

      {!ritualsReport.loading && (
        <Grid container style={{ marginTop: theme.spacing(3) }} spacing={3}>
          <TeamOverview
            teamRitualsOverview={ritualsReport.data.teamRitualsOverview}
          />
          <TeamReport
            teamRitualsReport={ritualsReport.data.teamRitualsReport}
          />
        </Grid>
      )}
    </RootDiv>
  );
};

export default TeamReporting;
