import { Helpline } from "./types";

export const helpLines: Helpline[] = [
  { country: "New Zealand", phoneNumber: "1737" },
  { country: "Australia", phoneNumber: "1300 22 4636" },
];

export const lifeRingCallToAction = `If you are in distress or need to talk to someone about your mental
  wellbeing, call or text the free 24/7 mental wellbeing helpline for
  your country.`;
