import { useContext, useEffect, useState } from "react";
import { TeamsByCompanyIdApi } from "../../../services/api";
import { Team } from "../../../types/Team";
import { CreateRitualContext } from "../AddRitual";
import { TeamAssignmentMode } from "./teamAssignmentMode";
import TeamCreation from "./TeamCreation";
import TeamSelection from "./TeamSelection";

const MAX_NUMBER_OF_TEAMS = 100;

const TeamTab = ({ pageName }: { pageName: string }) => {
  const context = useContext(CreateRitualContext);
  if (!context) return null;

  const showCreateTeam =
    context.teamAssignmentMode === TeamAssignmentMode.create;
  const showTeamSelection =
    context.teamAssignmentMode === TeamAssignmentMode.select;

  const [teams, setTeams] = useState([]);

  useEffect(() => {
    if (context.showAssignTeam) {
      TeamsByCompanyIdApi({
        limit: MAX_NUMBER_OF_TEAMS,
        offset: 0,
        orderBy: "asc",
        companyId: context.companyId,
      }).then((response) => {
        setTeams(response.data.teams.map((team: Team) => team.name));
      });
    }
  }, [context.companyId, context.showAssignTeam]);

  if (!context.showAssignTeam) return <></>;

  return (
    <>
      {showTeamSelection && <TeamSelection teams={teams} pageName={pageName} />}
      {showCreateTeam && <TeamCreation pageName={pageName} />}
    </>
  );
};

export default TeamTab;
