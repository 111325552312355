export enum SubscriptionStatus {
  INVITED = "INVITED",
  LINKED = "LINKED",
  REMOVED = "REMOVED",
  USER_INITIATED = "USER_INITIATED",
  PAUSED = "PAUSED",
  MOVED = "MOVED",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  GRACE_PERIOD = "GRACE_PERIOD",
}
