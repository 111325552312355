import { Typography } from "@material-ui/core";
import { colors } from "../styling/styles/colors";
import theme from "../styling/theme";

const RemainingCharactersCount = ({
  stringInput,
  maxLength,
}: {
  stringInput: string;
  maxLength: number;
}) => {
  return (
    <Typography
      variant="subtitle1"
      style={{
        color:
          stringInput.length >= maxLength
            ? colors.groovRed[100]
            : colors.groovGrey[40],
        padding: theme.spacing(4, 0),
        fontWeight: 400,
      }}
    >
      {maxLength - stringInput.length} characters remaining
    </Typography>
  );
};

export default RemainingCharactersCount;
