import { Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { useContext, useRef, useState } from "react";
import { Button, FullSelectMenu } from "../../../components";
import VerifyEmailBelongsToTeamModal from "../../../components/modals/VerifyEmailBelongsToTeamModal";
import { AnalyticsAction } from "../../../services/analytics/analyticsAction";
import AnalyticsHelper from "../../../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../../../services/analytics/analyticsObjectType";
import { colors } from "../../../styling/styles/colors";
import theme from "../../../styling/theme";
import { CreateANewRitualPageTestId } from "../../../test/constants/createANewRitualPageTestId";
import { CreateRitualContext } from "../AddRitual";
import { TeamAssignmentMode } from "./teamAssignmentMode";

const useStyles = makeStyles(() => ({
  link: {
    padding: 0,
    margin: 0,
    color: colors.royalBlue,
  },
  suggestionButton: {
    paddingLeft: 0,
    paddingTop: 0,
  },
}));

interface Props {
  teams: string[];
  pageName: string;
}
const TeamSelection: React.FC<Props> = ({ teams, pageName }) => {
  const context = useContext(CreateRitualContext);

  if (!context) return null;

  const classes = useStyles();
  const [verifyAccessToTeamModalOpen, setVerifyAccessToTeamModalOpen] =
    useState(false);
  const chosenTeam = useRef("");

  const onTeamSelected = (name: string): void => {
    if (name) {
      setVerifyAccessToTeamModalOpen(true);
    }
    chosenTeam.current = name;
  };

  const onSuccessfulAccessVerification = (teamId: string): void => {
    setVerifyAccessToTeamModalOpen(false);
    if (teamId) {
      context.setNewTeamName(chosenTeam.current);
      context.setNewTeamId(teamId);
    }
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item xs={5}>
        <Typography
          variant="h5"
          align="left"
          style={{ marginBottom: theme.spacing(3) }}
        >
          Select an existing team to assign this ritual to
        </Typography>
        <FullSelectMenu
          data-testid={CreateANewRitualPageTestId.SelectExistingTeamDropDown}
          value={context.newTeamName}
          onChange={(e: any) => onTeamSelected(e.target.value)}
          items={teams.map((team) => {
            return { label: team, value: team };
          })}
        />
        <Divider
          style={{
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
          }}
        />
        <Button
          data-testid={CreateANewRitualPageTestId.CreateANewTeamLink}
          className={classes.suggestionButton}
          onClick={() => {
            AnalyticsHelper.getInstance().trackEvent(
              pageName,
              AnalyticsObjectType.button,
              AnalyticsAction.clicked,
              { name: "CreateNewTeam" }
            );
            context.setNewTeamName("");
            context.setNewTeamId("");
            context.setTeamAssignmentMode(TeamAssignmentMode.create);
          }}
          disableRipple
          style={{ backgroundColor: "transparent" }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 400 }}
            className={classes.link}
          >
            Create a new team
          </Typography>
        </Button>
      </Grid>
      <VerifyEmailBelongsToTeamModal
        open={verifyAccessToTeamModalOpen}
        onSuccess={onSuccessfulAccessVerification}
        teamName={chosenTeam.current}
      />
    </Grid>
  );
};

export default TeamSelection;
