import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, IconButton, makeStyles, Modal } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { closeAuthModal } from "../../store/actions/actions";
import { colors } from "../../styling/styles/colors";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import CheckEmail from "./CheckEmail";
import { AuthView } from "./types/AuthView";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    width: "400px",
    position: "relative",
    margin: "auto",
    marginTop: "50px",
    paddingTop: "80px",
    padding: theme.spacing(8, 9),
    borderRadius: theme.spacing(6),
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(4),
    color: colors.groovGrey[60],
  },
}));

interface Props {
  showAuthUI: boolean;
  children: React.ReactNode;
}
const AuthProvider = (props: Props) => {
  const classes = useStyles();
  const [view, setView] = useState<AuthView>(AuthView.SignIn);

  const dispatch = useDispatch();

  const onModalClose = () => {
    setView(AuthView.SignIn);
    dispatch(closeAuthModal());
  };

  return (
    <>
      {props.children}
      {props.showAuthUI && (
        <Modal open={true} onClose={onModalClose}>
          <Box className={classes.container}>
            <IconButton
              className={classes.closeButton}
              aria-label="close"
              onClick={onModalClose}
            >
              <CloseIcon />
            </IconButton>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                textAlign: "center",
              }}
            >
              <Box>
                {view === AuthView.SignIn && <Login setView={setView} />}
                {view === AuthView.ForgotPassword && (
                  <ForgotPassword setView={setView} />
                )}
                {view === AuthView.CheckEmail && (
                  <CheckEmail setView={setView} />
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AuthProvider;
