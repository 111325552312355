import { call, put } from "redux-saga/effects";
import { GetEmbedTokenApi as GetEmbedTokenApi } from "../../services/embedApi";
import {
  GET_EMBED_TOKEN_FAILURE,
  GET_EMBED_TOKEN_SUCCESS,
} from "../actions/powerBIActions";

export function* GetEmbedToken(action: any): any {
  try {
    const response = yield call(GetEmbedTokenApi, action.payload);
    if (response) {
      yield put({
        type: GET_EMBED_TOKEN_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    yield put({
      type: GET_EMBED_TOKEN_FAILURE,
      payload: `Error: ${error.response?.data}`,
    });
  }
}
