import { Box, styled, Typography } from "@material-ui/core";
import { colors } from "../../styling/styles/colors";
import { LifeRingModalTestId } from "../../test/constants/lifeRingModalTestId";
import BasicModal from "../BasicModal";
import LifeRingIcon from "../svg/LifeRingIcon";
import { Helpline } from "./types";

const CountryLabel = styled(Typography)(({ theme }) => ({
  backgroundColor: colors.groovBlue[100],
  color: colors.white,
  height: "min-content",
  padding: theme.spacing(4, 8),
  width: theme.spacing(35),
  textAlign: "center",
  borderRadius: theme.spacing(8),
}));

const HelpLineContainer = styled(Box)(({ theme }) => ({
  backgroundColor: colors.white,
  padding: theme.spacing(4, 8),
  borderRadius: theme.spacing(8),
  marginTop: theme.spacing(8),
  border: `1px solid ${colors.mysticGrey}`,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const LifeRingIconContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginBottom: theme.spacing(2),
}));

export interface LifeRingModalProps {
  onClose: () => void;
  helpLines: Helpline[];
  lifeRingCallToAction: string;
}
export const LifeRingModal: React.FC<LifeRingModalProps> = ({
  helpLines,
  onClose,
  lifeRingCallToAction,
}) => {
  return (
    <BasicModal
      open
      onClose={onClose}
      modalSize="md"
      styles={{ backgroundColor: colors.floralWhite }}
    >
      <Box data-testid={LifeRingModalTestId.LifeRingModal}>
        <LifeRingIconContainer>
          <LifeRingIcon width={120} height={120} />
        </LifeRingIconContainer>
        <Typography
          align="center"
          variant="body1"
          style={{ color: colors.groovGrey[100] }}
        >
          {lifeRingCallToAction}
        </Typography>
        {helpLines.map((helpLine) => (
          <HelpLineContainer
            key={helpLine.country}
            data-testid={`${LifeRingModalTestId.HelpLine}-${helpLine.country}`}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5">Need to talk</Typography>
              <Typography
                variant="subtitle2"
                style={{ fontSize: 14, color: colors.taupeGrey }}
              >
                Free call or text
              </Typography>
              <Typography variant="h5" style={{ color: colors.groovBlue[100] }}>
                {helpLine.phoneNumber}
              </Typography>
            </Box>
            <CountryLabel>{helpLine.country}</CountryLabel>
          </HelpLineContainer>
        ))}
      </Box>
    </BasicModal>
  );
};

export default LifeRingModal;
