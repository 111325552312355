import { call, put } from "redux-saga/effects";
import { ToasterUtils } from "../../components";
import { GetRitualActionsApi, GetRitualTriggersApi } from "../../services/api";
import {
  GET_RITUAL_ACTIONS_SUCCESS,
  GET_RITUAL_ACTIONS_FAILURE,
  GET_RITUAL_TRIGGERS_SUCCESS,
  GET_RITUAL_TRIGGERS_FAILURE,
} from "../actions/actions";

export function* GetRitualActions(action: any): any {
  try {
    const response = yield call(GetRitualActionsApi);
    if (response) {
      yield put({
        type: GET_RITUAL_ACTIONS_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    ToasterUtils.error(error.response?.data.message);
    yield put({
      type: GET_RITUAL_ACTIONS_FAILURE,
      payload: error.response?.data,
    });
  }
}

export function* GetRitualTriggers(action: any): any {
  try {
    const response = yield call(GetRitualTriggersApi);
    if (response) {
      yield put({
        type: GET_RITUAL_TRIGGERS_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    ToasterUtils.error(error.response?.data.message);
    yield put({
      type: GET_RITUAL_TRIGGERS_FAILURE,
      payload: error.response?.data,
    });
  }
}
