import { Form, Formik, FormikHandlers, FormikState } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Grid,
  InputAdornment,
  styled,
  Typography,
} from "@material-ui/core";

import groovLogo from "../../assets/groov_logo.png";
import adminScreenImage from "../../assets/mentemia-admin-screen.png";
import {
  closeAuthModal,
  updateUserAccessToken,
} from "../../store/actions/actions";
import { colors } from "../../styling/styles/colors";
import theme from "../../styling/theme";
import { Button } from "../Button";
import LockIcon from "../svg/LockIcon";
import UserIcon from "../svg/UserIcon";
import { Input } from "../TextInput";
import { ToasterUtils } from "../Toaster";
import { login } from "../../services/authApi";
import { AuthView } from "./types/AuthView";

const HideButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.spacing(8),
  color: "white",
  textTransform: "none",
  "&:hover": {
    background: theme.palette.primary.main,
  },
}));

const ShowButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(8),
  color: theme.palette.primary.main,
  textTransform: "none",
  fontWeight: "bold",
  "&:hover": {
    background: theme.palette.background.default,
  },
}));

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: "",
  password: "",
};

interface Props {
  setView: (view: AuthView) => void;
}
const Login: React.FC<Props> = ({ setView }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (formValues: FormValues) => {
    try {
      const loginResult = await login(formValues.email, formValues.password);
      dispatch(updateUserAccessToken(loginResult.accessToken));
      ToasterUtils.success(`Signed in as ${formValues.email}`);
      dispatch(closeAuthModal());
    } catch (error) {
      ToasterUtils.error(`Error signing in`);
    }
  };

  return (
    <>
      <Box component="div" mb={4}>
        <img src={groovLogo} alt="groov logo" />
      </Box>
      <Box component="div" mb={4}>
        <img src={adminScreenImage} alt="admin" />
      </Box>
      <Typography
        align="center"
        variant="h1"
        style={{
          marginBottom: 4,
          marginTop: 4,
        }}
      >
        {"Sign in"}
      </Typography>
      <Typography variant="h6" style={{ color: colors.groovGrey[60] }}>
        Enter your sign in details
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
      >
        {(props: FormikState<FormValues> & FormikHandlers) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props;
          return (
            <Form>
              <Grid
                container
                item
                component="div"
                alignItems="center"
                direction="column"
              >
                <Input
                  error={errors.email && touched.email}
                  fullWidth={true}
                  name="email"
                  InputProps={{
                    style: {
                      paddingLeft: theme.spacing(6),
                      marginTop: theme.spacing(2),
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <UserIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.email &&
                    touched.email && (
                      <Typography align="center" variant="subtitle2">
                        {errors.email}
                      </Typography>
                    )
                  }
                  placeholder="Email"
                />
                <Input
                  error={errors.password && touched.password}
                  fullWidth={true}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: { paddingLeft: theme.spacing(5) },
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon width={24} height={24} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        {showPassword ? (
                          <HideButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            Hide
                          </HideButton>
                        ) : (
                          <ShowButton
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            Show
                          </ShowButton>
                        )}
                      </InputAdornment>
                    ),
                    type: showPassword ? "text" : "password",
                  }}
                  helperText={
                    touched.password &&
                    errors.password && (
                      <Typography
                        variant="subtitle2"
                        align="center"
                        style={{ color: "inherit" }}
                      >
                        Must be at least 6 characters and a mix of numbers{" "}
                        <br />
                        and letter in upper and lowercase
                      </Typography>
                    )
                  }
                  placeholder="Password"
                />
                <Button
                  onClick={() => onSubmit(values)}
                  disabled={isSubmitting}
                  variant="contained"
                  style={{
                    marginTop: theme.spacing(8),
                    marginBottom: theme.spacing(2),
                    minWidth: theme.spacing(20),
                  }}
                >
                  {isSubmitting ? <div>Loading...</div> : "Sign in"}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Button
        onClick={() => setView(AuthView.ForgotPassword)}
        style={{
          marginBottom: theme.spacing(2),
          minWidth: theme.spacing(20),
        }}
      >
        Forgot Password
      </Button>
    </>
  );
};

export default Login;
