import { combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import expireIn from "redux-persist-transform-expire-in";
import CompanyReducer from "./CompanyReducer";
import TeamsReducer from "./TeamsReducer";
import RitualsReducer from "./RitualsReducer";
import AccessReducer from "./AccessReducer";
import RitualSuggestionsReducer from "./RitualSuggestionsReducer";
import RitualsReportReducer from "./RitualsReportReducer";
import AuthReducer from "./AuthReducer";
import { RESET_STORE } from "../actions/actions";
import PowerBIReducer from "./PowerBIReducer";

const expiresIn = 30 * 60 * 1000; // 30 minutes
const key = "access";

const persistConfig = {
  key,
  storage,
  transforms: [expireIn(expiresIn, key, false)],
};

const appReducer = combineReducers({
  company: CompanyReducer,
  teams: TeamsReducer,
  rituals: RitualsReducer,
  ritualsReport: RitualsReportReducer,
  access: persistReducer(persistConfig, AccessReducer),
  ritualSuggestions: RitualSuggestionsReducer,
  auth: AuthReducer,
  powerBI: PowerBIReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(rootReducer);
export default store;
