import { Grid, makeStyles, Typography } from "@material-ui/core";
import { colors } from "../../../styling/styles/colors";
import theme from "../../../styling/theme";
import MaterialLink from "@material-ui/core/Link";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Link, Button } from "../../../components";
import { GeneralTestId } from "../../../test/constants/generalTestId";

const useStyles = makeStyles(() => ({
  link: {
    padding: 0,
    margin: 0,
    color: colors.royalBlue,
  },
}));

const Navigation = ({
  companyLevel,
  companyId,
  teamId,
  canContinue,
  onContinueClicked,
}: {
  companyLevel: boolean;
  companyId: string;
  teamId: string;
  canContinue: () => boolean;
  onContinueClicked: () => void;
}) => {
  const classes = useStyles();
  const canProceed = canContinue();

  return (
    <Grid
      container
      spacing={10}
      style={{
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(5),
      }}
    >
      <Grid container item xs={12} direction="row" justifyContent="center">
        <HelpOutlineIcon
          style={{
            width: "22px",
            height: "22px",
            color: colors.royalBlue,
            paddingRight: theme.spacing(1),
          }}
        />
        <MaterialLink
          href={`https://resources.groovnow.com/rituals#team-rituals`}
          target="_blank"
          rel="noreferrer"
          underline="none"
        >
          <Typography variant="subtitle1">Learn more</Typography>
        </MaterialLink>
      </Grid>
      <Grid container item xs={6} direction="row" justifyContent="flex-end">
        <Link
          data-testid={GeneralTestId.CancelButton}
          href={
            companyLevel ? `/${companyId}` : `/${companyId}/${teamId}/rituals`
          }
        >
          <Typography variant="subtitle1" className={classes.link}>
            Cancel
          </Typography>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Button
          data-testid={GeneralTestId.ConfirmActionButton}
          onClick={onContinueClicked}
          variant="contained"
          disabled={!canProceed}
        >
          Continue
        </Button>
      </Grid>
    </Grid>
  );
};

export default Navigation;
