import { OverviewCard } from "@groov/ui";
import { Grid } from "@material-ui/core";
import { TeamRitualsOverview } from "../../types/TeamRitualsOverview";

interface Props {
  teamRitualsOverview: TeamRitualsOverview[];
}

export const TeamOverview: React.FC<Props> = ({ teamRitualsOverview }) => {
  return (
    <>
      {teamRitualsOverview
        .sort((a, b) => {
          return a.order - b.order;
        })
        .map((data) => {
          return (
            <Grid item xs={12} sm={6} lg={4} key={data.title}>
              <OverviewCard title={data.title} count={data.count} />
            </Grid>
          );
        })}
    </>
  );
};
