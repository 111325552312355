import { Box, Typography } from "@material-ui/core";
import theme from "../../styling/theme";
import { Button } from "../Button";
import checkEmailImage from "../../assets/check-email.png";
import groovLogo from "../../assets/groov_logo.png";
import { colors } from "../../styling/styles/colors";
import { AuthView } from "./types/AuthView";

const CHECK_EMAIL_TEXT =
  "Check your email and follow the link we sent to reset your password. Can’t find it? Please check in your spam folder as they sometimes end up there.";

interface Props {
  setView: (view: AuthView) => void;
}
const CheckEmail: React.FC<Props> = ({ setView }) => {
  return (
    <>
      <Box component="div" mb={4}>
        <img src={groovLogo} alt="groov logo" />
      </Box>
      <Box component="div" mb={4}>
        <img src={checkEmailImage} alt="check email image" />
      </Box>
      <Typography
        align="center"
        variant="h1"
        style={{
          marginBottom: 4,
          marginTop: 4,
        }}
      >
        {"Check your email"}
      </Typography>
      <Typography
        align="center"
        variant="h6"
        style={{
          color: colors.groovGrey[60],
          maxWidth: 300,
          whiteSpace: "pre-wrap",
        }}
      >
        {CHECK_EMAIL_TEXT}
      </Typography>
      <Button
        onClick={() => setView(AuthView.SignIn)}
        style={{
          marginTop: theme.spacing(8),
          marginBottom: theme.spacing(2),
          minWidth: theme.spacing(20),
        }}
      >
        Back to login
      </Button>

      <Typography component="div" align="center" variant="subtitle1">
        Didn&apos;t receive an email?{" "}
        <Button
          onClick={() => setView(AuthView.ForgotPassword)}
          style={{
            minWidth: theme.spacing(20),
            padding: theme.spacing(4, 0),
          }}
        >
          Try Again
        </Button>
      </Typography>
    </>
  );
};

export default CheckEmail;
