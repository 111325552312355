import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getRitualActions, getRitualTriggers } from "../store/actions/actions";
import { RitualSuggestionsState } from "../store/reducers/RitualSuggestionsReducer";

const useRitualSuggestions = (tag: string) => {
  const dispatch = useDispatch();
  const { actions, actionsLoaded, triggers, triggersLoaded } = useSelector(
    (state: RootStateOrAny): RitualSuggestionsState => state.ritualSuggestions
  );

  useEffect(() => {
    if (!actionsLoaded) {
      dispatch(getRitualActions());
    }

    if (!triggersLoaded) {
      dispatch(getRitualTriggers());
    }
  }, [tag, dispatch]);

  return {
    suggestedActions: actions.filter((a) => !tag || a.tags.includes(tag)),
    suggestedTriggers: triggers.filter((a) => !tag || a.tags.includes(tag)),
  };
};

export default useRitualSuggestions;
