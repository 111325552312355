import logo from "../assets/groov_logo.png";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Box, makeStyles, styled, Typography } from "@material-ui/core";
import { colors } from "../styling/styles/colors";
import { useFlags } from "launchdarkly-react-client-sdk";
import { QuickNav } from "@groov/ui";
import { USE_NEW_APP_HEADER } from "../constants/features";
import { deleteUserAccessToken, openAuthModal } from "../store/actions/actions";
import { parseAccessToken } from "../utils/parseAccessToken";
import { cryptographyConfig } from "../utils/config";
import { Link } from "./Link";
import LifeRingIcon from "./svg/LifeRingIcon";
import { Button } from "./Button";
import { useContext, useEffect } from "react";
import { LifeRingContext } from "./lifeRing/LifeRingProvider";
import AnalyticsHelper from "../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../services/analytics/analyticsObjectType";
import { AnalyticsAction } from "../services/analytics/analyticsAction";
import "../styling/styles/QuickNav.css";

const useStyles = makeStyles(() => ({
  companyName: {
    color: colors.groovGrey[40],
  },
}));

const RootDiv = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 19),
}));

const LifeRingContainer = styled(Button)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  marginRight: theme.spacing(9),
}));

const HomeButton = styled(Link)(() => ({
  padding: 0,
  margin: 0,
}));

const CONTEXT = "Header";
const HEADER_SCROLL_THRESHOLD = 72;

const Header = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector((state: RootStateOrAny) => state.company);
  const { userAccessToken } = useSelector(
    (state: RootStateOrAny) => state.auth
  );
  const companyName = company?.name || "";
  const { org_id } = parseAccessToken(userAccessToken);
  const context = useContext(LifeRingContext);

  const flags = useFlags();

  useEffect(() => {
    window.addEventListener("scroll", isLfeRingSticky);
    return () => {
      window.removeEventListener("scroll", isLfeRingSticky);
    };
  });

  const isLfeRingSticky = () => {
    const header = document.querySelector(".life-ring-container");
    const scrollTop = window.scrollY;
    scrollTop >= HEADER_SCROLL_THRESHOLD
      ? header?.classList.add("is-sticky")
      : header?.classList.remove("is-sticky");
  };

  const goToCompanyHomePage = () => {
    if (company?.id) {
      document.location.href = `/${company.id}`;
    }
  };

  const onLifeRingClick = () => {
    AnalyticsHelper.getInstance().trackEvent(
      CONTEXT,
      AnalyticsObjectType.lifeRing,
      AnalyticsAction.clicked
    );
    context?.setShowLifeRingModal(true);
  };

  return (
    <RootDiv>
      <header>
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box
            style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
          >
            <HomeButton
              style={{ maxWidth: "fit-content" }}
              onClick={goToCompanyHomePage}
            >
              <img width={"124px"} src={logo} alt={"logo"} />
            </HomeButton>
            <Typography variant="h5" className={classes.companyName}>
              {companyName}
            </Typography>
          </Box>
          <LifeRingContainer
            onClick={onLifeRingClick}
            className="life-ring-container"
          >
            <LifeRingIcon />
          </LifeRingContainer>
          {flags[USE_NEW_APP_HEADER] && (
            <QuickNav
              accessToken={userAccessToken}
              encryptionKey={cryptographyConfig.encryptionKey}
              onSignInClick={() => dispatch(openAuthModal())}
              onSignOutClick={() => dispatch(deleteUserAccessToken())}
              organisationId={org_id}
            />
          )}
        </Box>
      </header>
    </RootDiv>
  );
};

export default Header;
