import { Form, Formik, FormikHandlers, FormikState } from "formik";

import { Box, Grid, InputAdornment, Typography } from "@material-ui/core";

import adminScreenImage from "../../assets/mentemia-admin-screen.png";
import groovLogo from "../../assets/groov_logo.png";

import { colors } from "../../styling/styles/colors";
import theme from "../../styling/theme";
import { Button } from "../Button";
import UserIcon from "../svg/UserIcon";
import { Input } from "../TextInput";
import { resetPassword } from "../../services/authApi";
import { validateEmail } from "../../utils/validation";
import { ToasterUtils } from "../Toaster";
import { AuthView } from "./types/AuthView";

const FORGOT_PASSWORD_TEXT =
  "Enter your email address and we’ll send instructions to reset your password.";

interface FormValues {
  email: string;
}

interface Props {
  setView: (view: AuthView) => void;
}

const ForgotPassword: React.FC<Props> = ({ setView }) => {
  const initialValues: FormValues = {
    email: "",
  };

  const onSubmit = async (values: FormValues) => {
    if (!validateEmail(values.email)) {
      ToasterUtils.error("Email address is invalid.");
      return;
    }

    await resetPassword(values.email);
    setView(AuthView.CheckEmail);
  };

  return (
    <>
      <Box component="div" mb={4}>
        <img src={groovLogo} alt="groov logo" />
      </Box>
      <Box component="div" mb={4}>
        <img src={adminScreenImage} alt="admin" />
      </Box>
      <Typography
        align="center"
        variant="h1"
        style={{
          marginBottom: 4,
          marginTop: 4,
        }}
      >
        {"Forgot your password?"}
      </Typography>
      <Typography
        align="center"
        variant="h6"
        style={{
          color: colors.groovGrey[60],
          maxWidth: 300,
          whiteSpace: "pre-wrap",
        }}
      >
        {FORGOT_PASSWORD_TEXT}
      </Typography>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(props: FormikState<FormValues> & FormikHandlers) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
          } = props;
          return (
            <Form>
              <Grid container item alignItems="center" direction="column">
                <Input
                  error={errors.email && touched.email}
                  fullWidth={true}
                  name="email"
                  InputProps={{
                    style: {
                      paddingLeft: theme.spacing(6),
                      marginTop: theme.spacing(2),
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <UserIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    errors.email &&
                    touched.email && (
                      <Typography
                        component="span"
                        align="center"
                        variant="subtitle2"
                      >
                        {errors.email}
                      </Typography>
                    )
                  }
                  placeholder="Email"
                />
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  style={{
                    marginTop: theme.spacing(8),
                    marginBottom: theme.spacing(2),
                    minWidth: theme.spacing(20),
                  }}
                >
                  {isSubmitting ? <div>Loading...</div> : "Reset your password"}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <Typography
        component="div"
        align="center"
        variant="subtitle1"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Already have an account?
        <Button
          onClick={() => setView(AuthView.SignIn)}
          style={{
            padding: theme.spacing(4, 0),
            minWidth: theme.spacing(16),
          }}
        >
          Sign in
        </Button>
      </Typography>
    </>
  );
};

export default ForgotPassword;
