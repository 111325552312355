import { call, put } from "redux-saga/effects";
import {
  GetTeamRitualsOverviewApi,
  GetTeamRitualsReportApi,
} from "../../services/hubApi";
import {
  GET_TEAM_RITUALS_OVERVIEW_FAILURE,
  GET_TEAM_RITUALS_OVERVIEW_SUCCESS,
  GET_TEAM_RITUALS_REPORT_FAILURE,
  GET_TEAM_RITUALS_REPORT_SUCCESS,
} from "../actions/ritualsReportActions";

export function* GetTeamRitualsReport(action: any): any {
  try {
    const response = yield call(GetTeamRitualsReportApi, action.payload);
    if (response) {
      yield put({
        type: GET_TEAM_RITUALS_REPORT_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    yield put({
      type: GET_TEAM_RITUALS_REPORT_FAILURE,
      payload: `Error: ${error.response?.data}`,
    });
  }
}

export function* GetTeamRitualsOverview(action: any): any {
  try {
    const response = yield call(GetTeamRitualsOverviewApi, action.payload);
    if (response) {
      yield put({
        type: GET_TEAM_RITUALS_OVERVIEW_SUCCESS,
        payload: response.data,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    yield put({
      type: GET_TEAM_RITUALS_OVERVIEW_FAILURE,
      payload: `Error: ${error.response?.data}`,
    });
  }
}
