import { exchangeToken } from "../../services/authApi";
import { UPDATE_USER_ACCESS_TOKEN } from "../actions/actions";
import { put, call } from "redux-saga/effects";
import { ToasterUtils } from "../../components";
import { AxiosResponse } from "axios";

export function* ExchangeToken(action: any) {
  try {
    const response: AxiosResponse = yield call(exchangeToken, action.payload);
    if (response) {
      yield put({
        type: UPDATE_USER_ACCESS_TOKEN,
        payload: response,
      });
    } else {
      throw response;
    }
  } catch (error: any) {
    ToasterUtils.error(error.response?.data.message);
  }
}
