import { createContext, useState } from "react";
import { AnalyticsAction } from "../../services/analytics/analyticsAction";
import AnalyticsHelper from "../../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../../services/analytics/analyticsObjectType";
import { helpLines, lifeRingCallToAction } from "./constants";
import LifeRingModal, { LifeRingModalProps } from "./lifeRingModal";

interface LifeRingState {
  showLifeRingModal: boolean;
  setShowLifeRingModal: (show: boolean) => void;
}
interface Props {
  children: React.ReactNode;
}
const Context = "LifeRing";
export const LifeRingContext = createContext<LifeRingState | null>(null);

export const LifeRingProvider: React.FC<Props> = ({ children }) => {
  const [showLifeRingModal, setShowLifeRingModal] = useState(false);

  const contextValue: LifeRingState = {
    showLifeRingModal,
    setShowLifeRingModal,
  };

  const onClose = () => {
    AnalyticsHelper.getInstance().trackEvent(
      Context,
      AnalyticsObjectType.modal,
      AnalyticsAction.closed,
      { name: "LifeRingModal" }
    );
    setShowLifeRingModal(false);
  };

  const lifeRingModalProps: LifeRingModalProps = {
    onClose,
    helpLines,
    lifeRingCallToAction,
  };

  return (
    <LifeRingContext.Provider value={contextValue}>
      {children}
      {showLifeRingModal && <LifeRingModal {...lifeRingModalProps} />}
    </LifeRingContext.Provider>
  );
};

export default LifeRingProvider;
