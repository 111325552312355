import { ToasterUtils } from "../components/Toaster/ToasterUtils";

export const validateName = (name: string): boolean => {
  return name !== "" && name.length >= 1;
};

export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(email);
};

export const validateNewTeamAndRitual = (
  name: string,
  memberEmail: string,
  confirmEmail: string,
  action: string,
  trigger: string
): boolean => {
  if (validateName(name)) {
    if (validateEmail(memberEmail)) {
      if (memberEmail === confirmEmail) {
        if (action !== "" && trigger !== "") {
          return true;
        } else {
          ToasterUtils.error(`Action or trigger can't be empty!!`);
        }
      } else {
        ToasterUtils.error("The email address does not match");
      }
    } else {
      if (memberEmail === "") {
        ToasterUtils.error("Enter leader email");
      } else {
        ToasterUtils.error("Check email format");
      }
    }
  } else {
    ToasterUtils.error("Enter team name");
  }
  return false;
};
