export {};
declare global {
  interface Array<T> {
    isLastItem(item: T): boolean;
  }
}

if (!Array.prototype.isLastItem) {
  Array.prototype.isLastItem = function <T>(this: T[], item: T): boolean {
    const index = this.indexOf(item);
    return !!this.length && index === this.length - 1;
  };
}
