import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Route, Router } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";

import { ToasterConfig } from "./components";
import Layout from "./components/Layout";
import AddRitual from "./pages/Rituals/AddRitual";
import Ideas from "./pages/Rituals/Ideas";
import Rituals from "./pages/Rituals/Rituals";
import Success from "./pages/Success";
import AddTeams from "./pages/Teams/AddTeams";
import Teams from "./pages/Teams/Teams";
import AnalyticsHelper from "./services/analytics/analyticsHelper";
import theme from "./styling/theme";
import history from "./utils/history";
import AuthProvider from "./components/auth/AuthProvider";
import { useLDClient } from "launchdarkly-react-client-sdk";
import { constructLDUser } from "./utils/constructLDUser";
import TeamReporting from "./pages/Reporting/TeamReporting";
import LifeRingProvider from "./components/lifeRing/LifeRingProvider";
import RitualReporting from "./pages/Reporting/RitualReporting";
import "@groov/ui/dist/menu.css";

const App = () => {
  const { orgId } = useSelector((state: RootStateOrAny) => state.company);
  const { showAuthUI, userId } = useSelector(
    (state: RootStateOrAny) => state.auth
  );
  const ldClient = useLDClient();

  useEffect(() => {
    if (orgId) {
      AnalyticsHelper.getInstance().trackUser({ orgId: orgId });
    }
  }, [orgId]);

  useEffect(() => {
    if (orgId && ldClient) {
      ldClient.identify(constructLDUser(userId, orgId));
    }
  }, [orgId, ldClient, userId]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        autoHideDuration={5000}
      >
        <ToasterConfig />
        <LifeRingProvider>
          <AuthProvider showAuthUI={showAuthUI}>
            <Layout>
              <Router history={history}>
                <Route
                  exact
                  path={[
                    "/:companyId",
                    "/:companyId/teams",
                    "/:companyId/company_rituals",
                  ]}
                  component={Teams}
                />
                <Route
                  exact
                  path="/:companyId/teams/add"
                  component={AddTeams}
                />
                <Route
                  exact
                  path="/:companyId/:teamId/rituals/team-reporting"
                  component={TeamReporting}
                />
                <Route
                  exact
                  path="/:companyId/:teamId/rituals/:ritualId/ritual-reporting"
                  component={RitualReporting}
                />
                <Route
                  exact
                  path="/:companyId/:teamId/rituals"
                  component={Rituals}
                />

                <Route exact path="/:companyId/ideas" component={Ideas} />
                <Route
                  exact
                  path="/:companyId/ritual/add"
                  component={AddRitual}
                />
                <Route
                  exact
                  path="/:companyId/:teamId/ritual/add"
                  component={AddRitual}
                />
                <Route
                  exact
                  path="/:companyId/ritual/edit/:id"
                  component={AddRitual}
                />
                <Route
                  exact
                  path="/:companyId/teams/add/success"
                  component={Success}
                />
              </Router>
            </Layout>
          </AuthProvider>
        </LifeRingProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
