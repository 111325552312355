import { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getPowerBIEmbedToken } from "../store/actions/powerBIActions";

import { PowerBIState } from "../store/reducers/PowerBIReducer";

export const useEmbedTokenV2 = (reportId: string) => {
  const dispatch = useDispatch();
  const powerBIEmbedToken = useSelector(
    (state: RootStateOrAny): PowerBIState => state.powerBI.embedTokens[reportId]
  );

  useEffect(() => {
    dispatch(getPowerBIEmbedToken(reportId));
  }, [reportId]);

  return powerBIEmbedToken;
};
