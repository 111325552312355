import { Card, Grid, Box, Typography } from "@material-ui/core";
import { Button } from "../../../components";
import Celebration from "../../../components/Celebration";
import theme from "../../../styling/theme";
import { CreateANewRitualPageTestId } from "../../../test/constants/createANewRitualPageTestId";
import { GeneralTestId } from "../../../test/constants/generalTestId";

const CelebrationTab = ({
  showCelebration,
  onContinue,
  teamName,
}: {
  showCelebration: boolean;
  onContinue: VoidFunction;
  teamName: string;
}) => {
  if (!showCelebration) return <></>;

  return (
    <Card>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        direction="column"
      >
        <Celebration />
        <Box
          sx={{
            textAlign: "center",
            position: "absolute",
            top: theme.spacing(120),
          }}
        >
          <Typography variant="h2">Well done</Typography>
          <Typography
            variant="body1"
            data-testid={
              CreateANewRitualPageTestId.RitualCreationConfirmationText
            }
          >
            {`You have created a new ritual for "${teamName}"`}
          </Typography>
        </Box>
        <Button
          data-testid={GeneralTestId.ConfirmActionButton}
          onClick={onContinue}
          variant="contained"
          style={{
            marginTop: theme.spacing(30),
            width: theme.spacing(25),
            marginBottom: theme.spacing(10),
          }}
        >
          Continue
        </Button>
      </Grid>
    </Card>
  );
};

export default CelebrationTab;
