import { Grid, Typography } from "@material-ui/core";
import { colors } from "../styling/styles/colors";

import theme from "../styling/theme";
import { Link } from "./Link";
import StepComplete from "./svg/StepComplete";

const StepButton = ({
  step,
  text,
  active,
  complete,
  goTo,
}: {
  step: number;
  text: string;
  active: boolean;
  complete: boolean;
  goTo?: VoidFunction;
}) => {
  return (
    <Grid item xs={3}>
      <Link onClick={complete ? goTo : undefined}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {complete ? (
            <StepComplete width={24} height={24} />
          ) : (
            <div
              style={{
                borderRadius: "100%",
                height: "24px",
                width: "24px",
                textAlign: "center",
                backgroundColor: active
                  ? colors.groovBlue[100]
                  : colors.groovGrey[40],
                color: colors.white,
                marginRight: theme.spacing(1),
              }}
            >
              <Typography variant="h5">{step}</Typography>
            </div>
          )}

          <Typography
            variant="h5"
            style={{
              borderBottom: active ? `2px solid ${colors.groovBlue[100]}` : "",
              color: active ? colors.groovBlue[100] : colors.groovGrey[40],
            }}
          >
            {text}
          </Typography>
        </div>
      </Link>
    </Grid>
  );
};

export default StepButton;
