import { createContext, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { createRitual, createTeam } from "../../store/actions/actions";
import { CHECKIN_FREQUENCY } from "../../types/CheckinFrequency";
import { Card, ToasterUtils } from "../../components";
import { colors } from "../../styling/styles/colors";
import { LightTooltip } from "../../components/LightTooltip";
import InfoIcon from "@material-ui/icons/Info";
import theme from "../../styling/theme";
import { RITUAL_CANNOT_BE_EMPTY } from "../../constants/errorMessages";
import AnalyticsHelper from "../../services/analytics/analyticsHelper";
import { AnalyticsAction } from "../../services/analytics/analyticsAction";
import { validateNewTeamAndRitual } from "../../utils/validation";
import { useFlags } from "launchdarkly-react-client-sdk";
import { ENABLE_GUIDED_RITUAL_SELECTION } from "../../constants/features";
import CheckInTab from "./GuidedRituals/CheckInTab";
import TeamTab from "./GuidedRituals/TeamTab";
import CelebrationTab from "./GuidedRituals/CelebrationTab";
import ContentTab from "./GuidedRituals/ContentTab";
import StepButton from "../../components/StepButton";
import Navigation from "./GuidedRituals/NavigationSection";
import { TeamAssignmentMode } from "./GuidedRituals/teamAssignmentMode";
import { AnalyticsObjectType } from "../../services/analytics/analyticsObjectType";

interface ParamTypes {
  id: string;
  companyId: string;
  teamId: string;
}
const RootDiv = styled.div`
  width: 60vw;
`;

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(10),
  },
  link: {
    padding: 0,
    margin: 0,
    color: colors.royalBlue,
  },
  description: { marginBottom: theme.spacing(6) },
  linkButton: {
    margin: theme.spacing(1),
  },
  inputRowText: {
    float: "left",
    minWidth: "fit-content",
    marginRight: theme.spacing(3),
  },
  subHeader: {
    marginBottom: theme.spacing(8),
  },
  updateDescription: {
    marginBottom: theme.spacing(16),
  },
  tooltip: {
    color: colors.silverSand,
    marginLeft: theme.spacing(2),
    height: "18px",
    width: "18px",
  },
  suggestionButton: {
    paddingLeft: 0,
  },
}));

const PAGE_NAME = "AddRitualPage";

interface CreateRitualContextInterface {
  companyId: string;
  showAssignTeam: boolean;
  newTeamId: string;
  setNewTeamId: (id: string) => void;
  newTeamName: string;
  setNewTeamName: (value: string) => void;
  newMemberEmail: string;
  setMemberEmail: (value: string) => void;
  confirmMemberEmail: string;
  setConfirmMemberEmail: (value: string) => void;
  newTeamDescription: string;
  setNewTeamDescription: (value: string) => void;
  teamAssignmentMode: TeamAssignmentMode;
  setTeamAssignmentMode: (mode: TeamAssignmentMode) => void;
  setShowAssignTeam: (value: boolean) => void;
}
export const CreateRitualContext =
  createContext<CreateRitualContextInterface | null>(null);

const AddRitual = () => {
  const teamName = useSelector(
    (state: RootStateOrAny) => state.rituals.data.name
  );
  const flags = useFlags();
  const { teamId, companyId } = useParams<ParamTypes>();
  const companyLevel = !teamId;
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [action, setAction] = useState("");
  const [trigger, setTrigger] = useState("");
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showAssignTeam, setShowAssignTeam] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [showCelebration, setShowCelebration] = useState(false);
  const [newTeamId, setNewTeamId] = useState("");
  const [newTeamName, setNewTeamName] = useState("");
  const [newMemberEmail, setMemberEmail] = useState("");
  const [confirmMemberEmail, setConfirmMemberEmail] = useState("");
  const [newTeamDescription, setNewTeamDescription] = useState("");
  const [checkinFrequency, setCheckInFrequency] = useState(
    CHECKIN_FREQUENCY.EVERY_MONTH.toString()
  );

  const [teamAssignmentMode, setTeamAssignmentMode] =
    useState<TeamAssignmentMode>(TeamAssignmentMode.select);

  const getCurrentTeamDetails = (): { teamId: string; teamName: string } => {
    if (!companyLevel) {
      return { teamId, teamName };
    } else {
      return { teamId: newTeamId, teamName: newTeamName };
    }
  };

  const displayTeamName = getCurrentTeamDetails().teamName;

  const hasAdminAccess = useSelector(
    (state: RootStateOrAny) => state.access.admin
  );

  const onCreateTeamCallback = (id: string) => {
    setNewTeamId(id);
    if (id) setShowCelebration(true);
  };

  const trackButtonClick = (name: string) => {
    AnalyticsHelper.getInstance().trackEvent(
      PAGE_NAME,
      AnalyticsObjectType.button,
      AnalyticsAction.clicked,
      { name }
    );
  };

  const handleSubmitRitual = () => {
    const resolvedTeamId = companyLevel ? newTeamId : teamId;
    const createData = {
      action: action,
      checkinFrequency: checkinFrequency,
      trigger: trigger,
      teamId: resolvedTeamId,
    };

    if (action !== "" && trigger !== "") {
      dispatch(createRitual(createData));

      setShowCheckIn(false);
      setShowCelebration(true);
    } else {
      ToasterUtils.error(RITUAL_CANNOT_BE_EMPTY);
    }
  };

  const handleSubmitTeamAndRitual = () => {
    const team = {
      name: newTeamName,
      leaderEmail: newMemberEmail,
      companyId,
      teamDescription: newTeamDescription,
    };
    const ritual = { action, trigger, checkinFrequency };
    const data = { ...team, ritual };

    const valid = validateNewTeamAndRitual(
      newTeamName,
      newMemberEmail,
      confirmMemberEmail,
      action,
      trigger
    );

    if (valid) {
      dispatch(
        createTeam(
          data,
          flags[ENABLE_GUIDED_RITUAL_SELECTION],
          onCreateTeamCallback
        )
      );
    }
  };

  const goToContent = () => {
    setShowCheckIn(false);
    setShowAssignTeam(false);
    setShowContent(true);
  };

  const goToTeamPage = () => {
    const teamToNavigateto = getCurrentTeamDetails().teamId;

    history.push(`/${companyId}/${teamToNavigateto}/rituals`);
    // with the current access control implementation
    // and business requirement of allowing a user to finish creating a ritual even if access is revoked already
    // this hack below is needed to refresh the page and show that access is already invalid
    history.go(0);
  };

  const goToCheckIn = () => {
    if (action !== "" && trigger !== "") {
      setShowCheckIn(true);
      setShowContent(false);
      setShowAssignTeam(false);
    } else {
      ToasterUtils.error(RITUAL_CANNOT_BE_EMPTY);
    }
  };

  const goToTeam = () => {
    setShowAssignTeam(true);
    setShowCheckIn(false);
    setShowContent(false);
  };

  const onCheckInFrequencyChanged = (frequency: string) => {
    AnalyticsHelper.getInstance().trackEvent(
      PAGE_NAME,
      AnalyticsObjectType.dropDown,
      AnalyticsAction.changed,
      { name: "checkInFrequency", frequency }
    );

    setCheckInFrequency(frequency);
  };

  const canContinue = () => {
    if (showContent) {
      return !!action && !!trigger;
    }

    if (showAssignTeam && teamAssignmentMode === TeamAssignmentMode.select) {
      return !!newTeamId;
    }

    return true;
  };

  if (!hasAdminAccess && !companyLevel) {
    goToTeamPage();
  }

  const onContinueClicked = () => {
    trackButtonClick("Continue");
    switch (true) {
      case showContent: {
        goToCheckIn();
        return;
      }
      case showCheckIn: {
        companyLevel ? goToTeam() : handleSubmitRitual();
        return;
      }
      case showAssignTeam && teamAssignmentMode === TeamAssignmentMode.create: {
        handleSubmitTeamAndRitual();
        return;
      }
      case showAssignTeam && teamAssignmentMode === TeamAssignmentMode.select:
        if (!newTeamId) {
          ToasterUtils.error("Please select a team to continue.");
          return;
        }

        handleSubmitRitual();
        return;
      default:
        ToasterUtils.error("Something went wrong, cannot continue.");
        return;
    }
  };

  return (
    <CreateRitualContext.Provider
      value={{
        companyId,
        newTeamId,
        newTeamDescription,
        setNewTeamId,
        setNewTeamDescription,
        setNewTeamName,
        newTeamName,
        newMemberEmail,
        setMemberEmail,
        confirmMemberEmail,
        setConfirmMemberEmail,
        teamAssignmentMode,
        setTeamAssignmentMode,
        showAssignTeam,
        setShowAssignTeam,
      }}
    >
      <RootDiv>
        {!showCelebration && (
          <Card>
            <Typography
              variant="h3"
              component="h3"
              gutterBottom
              align="center"
              style={{
                marginBottom: theme.spacing(8),
              }}
            >
              Create new ritual
              <LightTooltip
                title={
                  <p>
                    Team rituals help to bake wellbeing into the workday. When
                    selecting a ritual, make sure to pick something enjoyable
                    that fits with you and your team’s ways of working. Click{" "}
                    <a
                      href="https://www.resources.groovnow.com/team-rituals"
                      target="_blank"
                      rel="noreferrer"
                    >
                      here
                    </a>{" "}
                    to learn more.
                  </p>
                }
                placement="top"
                interactive
              >
                <InfoIcon className={classes.tooltip} />
              </LightTooltip>
            </Typography>
            <Grid container spacing={2}>
              <Grid
                container
                spacing={4}
                style={{
                  marginBottom: theme.spacing(12),
                }}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <StepButton
                  step={1}
                  text={"Trigger and action"}
                  active={showContent}
                  complete={!showContent}
                  goTo={goToContent}
                />
                <StepButton
                  step={2}
                  text={"Check-in frequency"}
                  active={showCheckIn}
                  complete={companyLevel && showAssignTeam}
                  goTo={goToCheckIn}
                />
                {companyLevel && (
                  <StepButton
                    step={3}
                    text={"Assign to team"}
                    active={showAssignTeam}
                    complete={false}
                  />
                )}
              </Grid>
              <ContentTab
                showContent={showContent}
                pageName={PAGE_NAME}
                trigger={trigger}
                setTrigger={(t) => setTrigger(t)}
                action={action}
                setAction={(a) => setAction(a)}
              />
              <CheckInTab
                showCheckIn={showCheckIn}
                checkinFrequency={checkinFrequency}
                onCheckInFrequencyChanged={onCheckInFrequencyChanged}
              />
              <TeamTab pageName={PAGE_NAME} />
              <Navigation
                companyLevel={companyLevel}
                companyId={companyId}
                teamId={teamId}
                canContinue={canContinue}
                onContinueClicked={onContinueClicked}
              />
            </Grid>
          </Card>
        )}
        <CelebrationTab
          showCelebration={showCelebration}
          onContinue={goToTeamPage}
          teamName={displayTeamName}
        />
      </RootDiv>
    </CreateRitualContext.Provider>
  );
};

export default AddRitual;
