import { endpoints } from "../constants/endpoints";
import { adaptToLoginResponse } from "../utils/adaptToLoginResponse";
import { identityClient } from "./clients";

export const login = async (email: string, password: string) => {
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
  params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET || "");
  params.append("grant_type", "password");
  params.append("scope", "openid orgId status offline_access");
  // NOTE: identity service treats this as username
  params.append("username", email);
  params.append("password", password);

  const result = await identityClient.post(endpoints.token, params);
  if (result.status !== 200) {
    throw new Error("Failed to login");
  }

  const response = adaptToLoginResponse(result);
  return response;
};

export const resetPassword = async (email: string): Promise<boolean> => {
  const result = await identityClient.post(endpoints.resetPassword, { email });
  return result.status === 200;
};

export const exchangeToken = async ({
  accessToken,
}: {
  accessToken: string;
}): Promise<any> => {
  const params = new URLSearchParams();
  params.append("client_id", process.env.REACT_APP_CLIENT_ID || "");
  params.append("client_secret", process.env.REACT_APP_CLIENT_SECRET || "");
  params.append("grant_type", "token_exchange");
  params.append("scope", "openid orgId status offline_access");
  params.append("token", accessToken);

  const result = await identityClient.post(endpoints.token, params);
  if (result.status !== 200) {
    throw new Error("Failed to login");
  }

  const response = adaptToLoginResponse(result);
  return response;
};
