import { AxiosResponse } from "axios";

export const adaptToLoginResponse = ({
  data,
  status,
  statusText,
}: AxiosResponse) => {
  return {
    accessToken: data.access_token,
    expiry: data.expires_in,
    refreshToken: data.refresh_token,
    status,
    statusText,
  };
};
