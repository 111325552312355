import { Grid, Typography } from "@material-ui/core";
import { Link } from "../../../components";
import { colors } from "../../../styling/styles/colors";
import create from "../../../assets/icons/create.svg";
import theme from "../../../styling/theme";
import { pillars } from "../../../constants/pillars";
import { CreateANewRitualPageTestId } from "../../../test/constants/createANewRitualPageTestId";

const PillarsSection = ({
  onPillarClicked,
  selectedPillar,
}: {
  onPillarClicked: (value: string) => void;
  selectedPillar: string;
}) => {
  const PillarButton = ({
    image,
    imageWidth,
    text,
    selected,
    onClick,
  }: {
    image: string;
    imageWidth?: number;
    text: string;
    selected: boolean;
    onClick: (pillar: string) => void;
  }) => {
    return (
      <Grid item style={{ textAlign: "center", opacity: selected ? 1 : 0.4 }}>
        <Link onClick={() => onClick(text)}>
          <img src={image} width={imageWidth} alt={text} data-testid={text} />
        </Link>
        <Typography variant="h5">{text}</Typography>
      </Grid>
    );
  };

  return (
    <Grid item>
      <Grid container>
        <Grid item xs={12}>
          <Typography
            data-testid={CreateANewRitualPageTestId.PillarTitle}
            variant="h5"
            style={{
              marginBottom: theme.spacing(4),
            }}
          >
            Choose one of the 6 Pillars of wellbeing for ritual suggestions
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={6}
        style={{
          marginBottom: theme.spacing(4),
        }}
      >
        {pillars.map((pillar) => (
          <PillarButton
            key={pillar.name}
            text={pillar.name}
            image={pillar.image}
            onClick={onPillarClicked}
            selected={selectedPillar === pillar.name}
          />
        ))}

        <Grid item xs style={{ textAlign: "center" }}>
          <Link data-testid={CreateANewRitualPageTestId.CreateYourOwnRitualLink} onClick={() => onPillarClicked("")}>
            <img width={63} src={create} alt={"create"} />
          </Link>
          <Typography
            variant="h5"
            style={{
              color: colors.groovBlue[100],
            }}
          >
            Or, create your own ritual
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PillarsSection;
