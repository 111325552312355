export enum AnalyticsObjectType {
  button = "button",
  contextMenu = "contextMenu",
  contextMenuItem = "contextMenuItem",
  modal = "modal",
  input = "input",
  dropDown = "dropDown",
  breadcrumb = "breadcrumb",
  lifeRing = "lifeRing",
  card = "card",
}
