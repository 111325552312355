import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useContext } from "react";
import { Button, Input } from "../../../components";
import { AnalyticsAction } from "../../../services/analytics/analyticsAction";
import AnalyticsHelper from "../../../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../../../services/analytics/analyticsObjectType";
import { colors } from "../../../styling/styles/colors";
import theme from "../../../styling/theme";
import { AddANewTeamPageTestId } from "../../../test/constants/addANewTeamPageTestId";
import { CreateRitualContext } from "../AddRitual";
import { TeamAssignmentMode } from "./teamAssignmentMode";

const useStyles = makeStyles(() => ({
  link: {
    padding: 0,
    margin: 0,
    color: colors.royalBlue,
  },
  suggestionButton: {
    paddingLeft: 0,
    paddingBottom: 0,
  },
}));

const TeamCreation = ({ pageName }: { pageName: string }) => {
  const classes = useStyles();
  const context = useContext(CreateRitualContext);
  if (!context) return null;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <Button
          className={classes.suggestionButton}
          onClick={() => {
            AnalyticsHelper.getInstance().trackEvent(
              pageName,
              AnalyticsObjectType.button,
              AnalyticsAction.clicked,
              { name: "SelectExistingTeam" }
            );
            context.setNewTeamName("");
            context.setNewTeamId("");
            context.setTeamAssignmentMode(TeamAssignmentMode.select);
          }}
          disableRipple
          style={{ backgroundColor: "transparent" }}
        >
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 400 }}
            className={classes.link}
          >
            Select from an existing team
          </Typography>
        </Button>
        <div
          style={{
            paddingLeft: theme.spacing(2),
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(4),
              marginBottom: theme.spacing(4),
              borderBottom: `1px solid ${colors.groovGrey[20]}`,
            }}
          />
          <Typography
            variant="h5"
            style={{
              marginBottom: theme.spacing(2),
            }}
          >
            Team name
          </Typography>
          <Input
            data-testid={AddANewTeamPageTestId.TeamNameTextBox}
            fullWidth={true}
            value={context.newTeamName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              context.setNewTeamName(e.target.value)
            }
            style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
            placeholder="Enter your team name"
          />
          <Typography
            variant="h5"
            style={{
              marginBottom: theme.spacing(2),
            }}
          >
            Your email
          </Typography>
          <Input
            data-testid={AddANewTeamPageTestId.EmailTextBox}
            fullWidth={true}
            value={context.newMemberEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              context.setMemberEmail(e.target.value)
            }
            style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
            placeholder="Enter your email address"
          />
          <Typography
            variant="h5"
            style={{
              marginBottom: theme.spacing(2),
            }}
          >
            Confirm email
          </Typography>
          <Input
            data-testid={AddANewTeamPageTestId.ConfirmEmailTextBox}
            fullWidth={true}
            value={context.confirmMemberEmail}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              context.setConfirmMemberEmail(e.target.value)
            }
            style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
            placeholder="Enter your email address"
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography
                variant="h5"
                style={{
                  marginBottom: theme.spacing(2),
                }}
              >
                Team description
              </Typography>
            </Grid>
            <Grid container item xs={6} justifyContent="flex-end">
              <Typography
                variant="body1"
                style={{
                  marginBottom: theme.spacing(2),
                  color: colors.groovGrey[40],
                }}
              >
                Optional
              </Typography>
            </Grid>
          </Grid>
          <Input
            data-testid={AddANewTeamPageTestId.TeamDescriptionTextBox}
            fullWidth={true}
            value={context.newTeamDescription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              context.setNewTeamDescription(e.target.value)
            }
            style={{ marginTop: 0, marginBottom: theme.spacing(2) }}
            placeholder="Enter your team description"
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default TeamCreation;
