import Lottie from "lottie-react";
import confettiAnimation from "../constants/confetti.json";
import { CreateANewRitualPageTestId } from "../test/constants/createANewRitualPageTestId";

interface Props {
  width?: number;
  height?: number;
}
const Celebration: React.FC<Props> = ({ width = 400, height = 400 }) => {
  const style = {
    height,
    width,
  };

  return (
    <Lottie
      data-testid={CreateANewRitualPageTestId.CelebrationConfetti}
      animationData={confettiAnimation}
      style={style}
    />
  );
};

export default Celebration;
