import celebrate from "../assets/celebrate.png";
import chill from "../assets/chill.png";
import do_ from "../assets/do.png";
import connect from "../assets/connect.png";
import enjoy from "../assets/enjoy.png";
import move from "../assets/move.png";

export const pillars: { name: string; image: string }[] = [
  {
    name: "Chill",
    image: chill,
  },
  {
    name: "Connect",
    image: connect,
  },
  {
    name: "Do",
    image: do_,
  },
  {
    name: "Enjoy",
    image: enjoy,
  },
  {
    name: "Move",
    image: move,
  },
  {
    name: "Celebrate",
    image: celebrate,
  },
];
