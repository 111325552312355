import { TeamRitualsOverview } from "../../types/TeamRitualsOverview";
import { TeamRitualsReport } from "../../types/TeamRitualsReport";
import {
  GET_TEAM_RITUALS_OVERVIEW_BEGIN,
  GET_TEAM_RITUALS_OVERVIEW_FAILURE,
  GET_TEAM_RITUALS_OVERVIEW_SUCCESS,
  GET_TEAM_RITUALS_REPORT_BEGIN,
  GET_TEAM_RITUALS_REPORT_FAILURE,
  GET_TEAM_RITUALS_REPORT_SUCCESS,
} from "../actions/ritualsReportActions";

interface RitualsReportState {
  loading: boolean;
  error: string;
  data: {
    teamRitualsReport: TeamRitualsReport[];
    teamRitualsOverview: TeamRitualsOverview[];
  };
}

const initialState: RitualsReportState = {
  loading: false,
  error: "",
  data: {
    teamRitualsReport: [],
    teamRitualsOverview: [],
  },
};

const RitualsReportReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_TEAM_RITUALS_REPORT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_RITUALS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: {
          ...state.data,
          teamRitualsReport: action.payload.rituals ?? [],
        },
      };
    case GET_TEAM_RITUALS_REPORT_FAILURE:
      return {
        loading: false,
        data: {
          ...state.data,
          teamRitualsReport: [],
        },
        error: action.payload,
      };
    case GET_TEAM_RITUALS_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TEAM_RITUALS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        data: {
          ...state.data,
          teamRitualsOverview: action.payload.overview ?? [],
        },
      };
    case GET_TEAM_RITUALS_OVERVIEW_FAILURE:
      return {
        loading: false,
        data: {
          ...state.data,
          teamRitualsOverview: [],
        },
        error: action.payload,
      };
    default:
      return state;
  }
};

export default RitualsReportReducer;
