export const teamData = {
  name: 'Teams',
  rows: [
    {
      name: 'Dev',
      data: [
        {
          triggers: 'At the beginning of every meeting',
          actions: 'Share one thing you did well, one thing you learned.'
        },
        {
          triggers: 'At the end of every shift.',
          actions: 'Decorate someones desk, cubivle or office'
        },
        {
          triggers: 'Every Monday',
          actions: 'Bring in some home baking or a cale to share.'
        }
      ]
    },
    {
      name: 'Equipment team',
      data: [
        {
          triggers: 'Every Monday',
          actions: 'Share one thing you did well, one thing you learned.'
        },
        {
          triggers: 'At the end of every shift.',
          actions: 'Decorate someones desk, cubivle or office'
        },
        {
          triggers: 'Every Monday',
          actions: 'Bring in some home baking or a cale to share.'
        }
      ]
    }
  ]
};
export const ritualsData = [
  {
    id: 1,
    triggers: 'At the beginning of every meeting',
    actions: 'Share one thing you did well, one thing you learned.'
  },
  {
    id: 2,
    triggers: 'At the end of every shift.',
    actions: 'Decorate someones desk, cubivle or office'
  },
  {
    id: 3,
    triggers: 'Every Monday',
    actions: 'Bring in some home baking or a cale to share.'
  },
  {
    id: 4,
    triggers: 'At the end of every shift.',
    actions: 'Decorate someones desk, cubivle or office'
  },
  {
    id: 5,
    triggers: 'Every Monday',
    actions: 'Bring in some home baking or a cale to share.'
  }
];

export const ritualsIdeas = [
  {
    id: 1,
    trigger: 'At the beginning of the shift on Monday…',
    action: [
      'As a team, checking in with each other and share one thing that you enjoyed over the weekend.',
      'As a team, share one thing that was a win from the previous week'
    ]
  },
  {
    id: 2,
    trigger: ' At the beginning of each day…',
    action: [
      `As a team, put on some fun high-energy music to start the day - and take turns choosing!`,
      `As a team, put aside 1-minute to practice some deep breathing before you start work`
    ]
  },
  {
    id: 3,
    trigger:
      'Throughout the day, for example before, during or after lunch or in the afternoon…',
    action: [
      `As a team, give people permission to take 5-minutes to stretch or go for a short walk`,
      `As a team, take a tea or coffee break together in the break room or pop out`
    ]
  },
  {
    id: 4,
    trigger: 'At the end of a meeting…',
    action: [
      `As a team, agree to make 60-minute meetings 45-minutes to give people some time back during the day to find focus or have a breather`,
      `As a team, give people permission or time to spend a few minutes deep breathing`
    ]
  },
  {
    id: 5,
    trigger: ' At the start of a meeting…',
    action: [
      `As a team, have a shared lunch or go out for lunch together`,
      `As a team, make a time to discuss wins`,
      `As a team, spin the wheel of kindness and commit to completing one act of kindness and then share what you did and how it made you feel`
    ]
  }
];

