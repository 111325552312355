import "../utils/array.extensions";

import { Box, styled, Typography, useTheme } from "@material-ui/core";

import { colors } from "../styling/styles/colors";
import { BreadcrumbsTestId } from "../test/constants/breadcrumbsTestId";
import { Link } from "./Link";

export interface Crumb {
  text: string;
  href?: string;
}

interface Props {
  crumbs: Crumb[];
  separator?: string | React.ReactNode;
  onLinkClicked?: (crumb: Crumb) => void;
}

const Container = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

export const Breadcrumbs: React.FC<Props> = ({
  crumbs = [],
  separator = "/",
  onLinkClicked,
}) => {
  const theme = useTheme();

  const onBreadcrumbLinkClicked = (crumb: Crumb): void => {
    if (onLinkClicked) {
      onLinkClicked(crumb);
    }
  };

  return (
    <Container
      data-testid={BreadcrumbsTestId.BreadcrumbsContainer}
      style={{
        marginBottom: theme.spacing(8),
      }}
    >
      {crumbs.map((crumb, index) => {
        if (crumbs.isLastItem(crumb)) {
          return (
            <Typography
              variant="h5"
              key={index}
              component="span"
              data-testid={BreadcrumbsTestId.BreadcrumbCurrent}
              style={{ marginLeft: crumbs.length > 1 ? theme.spacing(1) : 0 }}
            >
              {crumb.text}
            </Typography>
          );
        }

        return (
          <Box key={`breadcrumb-${index}`}>
            <Link
              style={{ color: colors.groovNeutral[70] }}
              href={crumb.href}
              data-testid={`${BreadcrumbsTestId.BreadcrumbLinkPrefix}${index}`}
              onClick={() => onBreadcrumbLinkClicked(crumb)}
            >
              <Typography component="span" variant="body1">
                {crumb.text}
              </Typography>
            </Link>
            <Box color={colors.groovNeutral[70]} key={index} display="inline">
              {separator}
            </Box>
          </Box>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;
