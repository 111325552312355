export const CreateANewRitualPageTestId = {
  PillarTitle: "PillarTitle",
  Chill: "Chill",
  CreateYourOwnRitualLink: "CreateYourOwnRitualLink",
  TriggerTextBox: "TriggerTextBox",
  ActionTextBox: "ActionTextBox",
  SelectDropDown: "SelectDropDown",
  CelebrationConfetti: "CelebrationConfetti",
  RitualCreationConfirmationText: "RitualCreationConfirmationText",
  CreateANewTeamLink: "CreateANewTeamLink",
  SelectExistingTeamDropDown: "SelectExistingTeamDropDown",
};
