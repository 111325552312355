/**
 * Returns random items from an array without repeating until exhaustion
 * @returns T
 */
export class ArrayRandomizer<T> {
  private arrayIndexOffset = 0;
  constructor(private array: T[]) {}

  public getNextItem(): T | null {
    if (!this.array) return null;
    if (!this.array.length) return null;

    if (this.arrayIndexOffset === 0) {
      this.arrayIndexOffset = 1;
    }

    const randomIndex = Math.floor(
      Math.random() * (this.array.length - this.arrayIndexOffset)
    );
    const item = this.array[randomIndex];

    this.array.splice(randomIndex, 1);
    this.array.push(item);

    this.arrayIndexOffset++;
    if (this.arrayIndexOffset > this.array.length) this.arrayIndexOffset = 1;

    return item;
  }
}
