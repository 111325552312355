import { decryptString } from "@groov/ui";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { loginFromExternal } from "../store/actions/actions";
import { cryptographyConfig } from "../utils/config";

const ACCESS_TOKEN_KEY = "key";
export const withExternalLogin = (Component: any) => {
  // eslint-disable-next-line react/display-name
  return (props: unknown) => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
      const accessToken =
        new URLSearchParams(location.search).get(ACCESS_TOKEN_KEY) || "";
      const hasAccessToken = !!accessToken;
      if (hasAccessToken) {
        const key = cryptographyConfig.encryptionKey;
        const decryptedAccessToken = decryptString(
          key,
          decodeURIComponent(accessToken)
        );
        dispatch(loginFromExternal(decryptedAccessToken));
      }
    }, [dispatch, location.search]);

    return <Component {...(props as any)} />;
  };
};
