export const GET_TEAM_RITUALS_REPORT_BEGIN = "GET_TEAM_RITUALS_REPORT_BEGIN";
export const GET_TEAM_RITUALS_REPORT_SUCCESS =
  "GET_TEAM_RITUALS_REPORT_SUCCESS";
export const GET_TEAM_RITUALS_REPORT_FAILURE =
  "GET_TEAM_RITUALS_REPORT_FAILURE";

export const GET_TEAM_RITUALS_OVERVIEW_BEGIN =
  "GET_TEAM_RITUALS_OVERVIEW_BEGIN";
export const GET_TEAM_RITUALS_OVERVIEW_SUCCESS =
  "GET_TEAM_RITUALS_OVERVIEW_SUCCESS";
export const GET_TEAM_RITUALS_OVERVIEW_FAILURE =
  "GET_TEAM_RITUALS_OVERVIEW_FAILURE";

export const getTeamRitualsReport = (orgId: string, teamId: string) => ({
  type: GET_TEAM_RITUALS_REPORT_BEGIN,
  payload: { orgId, teamId },
});

export const getTeamRitualsOverview = (orgId: string, teamId: string) => ({
  type: GET_TEAM_RITUALS_OVERVIEW_BEGIN,
  payload: { orgId, teamId },
});
