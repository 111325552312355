import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {
  unauthenticatedRequestInterceptor,
  getNewAccessTokenOnTokenExpired,
} from "./interceptors";

const makeClient = (
  baseURL: string,
  config: AxiosRequestConfig = {}
): AxiosInstance => {
  const client = axios.create({ baseURL, ...config });

  client.interceptors.request.use(unauthenticatedRequestInterceptor);
  client.interceptors.response.use(
    (res) => res,
    getNewAccessTokenOnTokenExpired
  );

  return client;
};

export const identityClient = makeClient(
  process.env.REACT_APP_IDENTITY_SERVICE_URL ?? ""
);

export const platformClient = makeClient(
  process.env.REACT_APP_PLATFORM_SERVICE_URL ?? ""
);

export const hubClient = makeClient(process.env.REACT_APP_HUB_API_URL ?? "");

export const reportClient = makeClient(
  process.env.REACT_APP_REPORT_AUTH_URL ?? ""
);
