import {
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  IconButton,
  Link,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { colors } from "../styling/styles/colors";

import appTheme from "../styling/theme";
import { TeamPageTestId } from "../test/constants/teamPageTestId";
import { Ritual } from "../types/Ritual";
import { getCheckinFrequencylabel } from "../utils/commonUtils";
import { formatDate } from "../utils/dateUtils";
import { Card } from "./Card";

interface Props {
  ritual: Ritual;
  showContextMenu: boolean;
  anchor?: any;
  onCloseMenu?: (e: any) => void;
  onContextMenuClick?: (e: any, ritual: Ritual) => void;
  onRemoveRitualClick?: () => void;
  onEditRitualClick?: () => void;
  onRitualCardClicked?: (ritualId: string) => void;
  showFooter?: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: colors.groovGrey[5],
    borderRadius: theme.spacing(3),
    padding: 0,
  },
  cardFooter: {
    padding: theme.spacing(4, 4),
  },
  cardHeader: {
    padding: theme.spacing(4, 4, 2, 4),
  },
  menu: {
    color: colors.black,
  },
  lastUpdated: {
    marginTop: appTheme.spacing(2),
    color: colors.groovGrey[60],
  },
}));

const RitualComponent: React.FC<Props> = ({
  ritual,
  showContextMenu,
  onCloseMenu,
  onContextMenuClick,
  anchor,
  onRemoveRitualClick,
  onEditRitualClick,
  onRitualCardClicked,
}) => {
  const classes = useStyles();

  const onMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    callback: any
  ): void => {
    event.stopPropagation();
    if (callback) {
      callback();
    }
  };

  return (
    <Card
      className={classes.container}
      data-testid={`${TeamPageTestId.RitualComponent}-${ritual.id}`}
    >
      <CardActionArea
        data-testid={`${TeamPageTestId.RitualComponent}-${ritual.id}-actionArea`}
        onClick={() => {
          if (onRitualCardClicked) {
            onRitualCardClicked(ritual.id);
          }
        }}
      >
        <CardHeader
          data-testid={TeamPageTestId.RitualTileHeader}
          className={classes.cardHeader}
          title={<Typography variant="h5">{ritual.trigger}</Typography>}
          action={
            <>
              {showContextMenu && (
                <IconButton
                  data-testid={TeamPageTestId.EditOrRemoveRitualMenuButton}
                  aria-label="more"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (onContextMenuClick) {
                      onContextMenuClick(e, ritual);
                    }
                  }}
                >
                  <MoreVertIcon className={classes.menu} />
                </IconButton>
              )}

              <Menu
                anchorEl={anchor}
                open={Boolean(anchor)}
                onClose={onCloseMenu}
              >
                <MenuItem
                  data-testid={TeamPageTestId.EditRitualLink}
                  onClick={(e) => onMenuItemClick(e, onEditRitualClick)}
                >
                  Edit ritual
                </MenuItem>
                <MenuItem
                  data-testid={TeamPageTestId.RemoveRitualLink}
                  onClick={(e) => onMenuItemClick(e, onRemoveRitualClick)}
                >
                  Remove ritual
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardContent style={{ padding: appTheme.spacing(2, 4, 4, 4) }}>
          <Typography variant="body1">{ritual.action}</Typography>
          <Typography
            variant="subtitle1"
            style={{ marginTop: appTheme.spacing(6) }}
          >
            {getCheckinFrequencylabel(ritual)}
          </Typography>
          <Typography variant="subtitle1" className={classes.lastUpdated}>
            Last updated {formatDate(ritual.lastUpdateTime)}
          </Typography>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardFooter}>
          <Link>
            <Typography>View ritual report</Typography>
          </Link>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default RitualComponent;
