import { hubClient } from "./clients";

export const GetTeamRitualsReportApi = async ({
  orgId,
  teamId,
}: {
  orgId: string;
  teamId: string;
}) => {
  const url = `/stats/${orgId}/rituals/${teamId}?orderBy=lastCheckin&sort=DESC`;
  return hubClient.get(url);
};

export const GetTeamRitualsOverviewApi = async ({
  orgId,
  teamId,
}: {
  orgId: string;
  teamId: string;
}) => {
  const url = `/stats/${orgId}/ritualsOverview/${teamId}`;
  return hubClient.get(url);
};
