import { colors } from "../../styling/styles/colors";
import theme from "../../styling/theme";

function StepComplete({ height = 38, width = 38 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginRight: theme.spacing(1),
      }}
    >
      <circle cx="19" cy="19" r="19" fill={colors.groovGreen[5]} />
      <path
        d="M11.5 20L17 25.5L26.5 12.5"
        stroke={colors.groovGreen[90]}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default StepComplete;
