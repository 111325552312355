import { LDUser } from "launchdarkly-js-sdk-common";

export const constructLDUser = (userId: string, orgId: string): LDUser => {
  const ldUser: LDUser = {};
  if (userId) {
    ldUser.key = userId;
  }
  return {
    key: userId,
    custom: { orgId, userId },
  };
};
