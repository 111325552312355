const RemoveUser = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99988 8C7.99988 5.79086 9.79074 4 11.9999 4C14.209 4 15.9999 5.79086 15.9999 8C15.9999 10.2091 14.209 12 11.9999 12C9.79074 12 7.99988 10.2091 7.99988 8ZM11.9999 2C8.68617 2 5.99988 4.68629 5.99988 8C5.99988 11.3137 8.68617 14 11.9999 14C15.3136 14 17.9999 11.3137 17.9999 8C17.9999 4.68629 15.3136 2 11.9999 2ZM18.4999 23C16.0146 23 13.9999 20.9853 13.9999 18.5C13.9999 16.0147 16.0146 14 18.4999 14C20.9852 14 22.9999 16.0147 22.9999 18.5C22.9999 20.9853 20.9852 23 18.4999 23ZM20.5022 19C20.785 19 20.9999 18.7761 20.9999 18.5C20.9999 18.2319 20.7771 18 20.5022 18H16.4976C16.2147 18 15.9999 18.2239 15.9999 18.5C15.9999 18.7681 16.2227 19 16.4976 19H20.5022ZM14.257 15H6.99988C4.20052 15 2.39023 16.1679 1.52546 18.0896C1.07412 19.0926 0.9489 20.1109 0.99543 20.9699C0.998488 21.0263 1.00137 21.0633 1.00485 21.0995C1.0598 21.649 1.54984 22.05 2.09939 21.995C2.64893 21.9401 3.04987 21.45 2.99492 20.9005C2.99544 20.9071 2.99388 20.8872 2.9925 20.8617C2.96174 20.2938 3.05009 19.5753 3.3493 18.9104C3.89079 17.7071 4.98675 17 6.99988 17H12.007H13.2069C13.4169 16.2576 13.7788 15.579 14.257 15Z"
        fill="#E7746A"
      />
    </svg>
  );
};

export default RemoveUser;
