import { Grid, Typography } from "@material-ui/core";
import { FullSelectMenu } from "../../../components";
import { colors } from "../../../styling/styles/colors";
import theme from "../../../styling/theme";
import { AddANewTeamPageTestId } from "../../../test/constants/addANewTeamPageTestId";
import { CHECKIN_FREQUENCY } from "../../../types/CheckinFrequency";

const CheckInTab = ({
  showCheckIn,
  checkinFrequency,
  onCheckInFrequencyChanged,
}: {
  showCheckIn: boolean;
  checkinFrequency: string;
  onCheckInFrequencyChanged: (frequency: string) => void;
}) => {
  if (!showCheckIn) return <></>;

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={5}>
        <Typography
          variant="h5"
          style={{
            marginBottom: theme.spacing(4),
          }}
        >
          Check-in frequency
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: colors.groovGrey[50],
            marginBottom: theme.spacing(4),
          }}
        >
          Set up a regular check-in to help make the wellbeing action stick.
          Also, adjust the ritual as needed to make it work better for you and
          your team.
        </Typography>
        <FullSelectMenu
          data-testid={AddANewTeamPageTestId.SelectDropDown}
          value={checkinFrequency}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onCheckInFrequencyChanged(e.target.value)
          }
          items={Object.values(CHECKIN_FREQUENCY).map((frequency) => {
            return { label: frequency, value: frequency };
          })}
        />
      </Grid>
    </Grid>
  );
};

export default CheckInTab;
