import { takeLatest, all } from "redux-saga/effects";
import {
  CREATE_TEAM_BEGIN,
  GET_COMPANY_BY_ID_BEGIN,
  GET_TEAMS_BY_COMPANY_ID_BEGIN,
  CREATE_RITUAL_BEGIN,
  UPDATE_RITUAL_BEGIN,
  DELETE_RITUAL_BEGIN,
  GET_RITUALS_BEGIN,
  GET_COMPANY_RITUAL_BY_COMPANY_ID_BEGIN,
  EDIT_TEAM_BEGIN,
  GET_TEAM_MEMBERS_BEGIN,
  DELETE_TEAM_MEMBER_BEGIN,
  CREATE_TEAM_MEMBER_BEGIN,
  GET_RITUAL_ACTIONS,
  GET_RITUAL_TRIGGERS,
  LOGIN_FROM_EXTERNAL,
} from "../actions/actions";
import {
  GET_TEAM_RITUALS_REPORT_BEGIN,
  GET_TEAM_RITUALS_OVERVIEW_BEGIN,
} from "../actions/ritualsReportActions";
import { company } from "./CompanySaga";
import {
  TeamsByCompanyId,
  CreateTeam,
  CompanyRitualByCompanyId,
  EditTeam,
  GetTeamMembers,
  DeleteTeamMember,
  CreateTeamMember,
} from "./TeamsSaga";
import {
  GetRituals,
  CreateRitual,
  UpdateRitual,
  DeleteRitual,
} from "./RitualSaga";
import { GetRitualActions, GetRitualTriggers } from "./RitualSuggestionsSaga";
import { ExchangeToken } from "./AuthSaga";
import {
  GetTeamRitualsOverview,
  GetTeamRitualsReport,
} from "./RitualsReportSaga";
import { GET_EMBED_TOKEN_BEGIN } from "../actions/powerBIActions";
import { GetEmbedToken } from "./PowerBISaga";

export default function* rootSaga() {
  yield all([
    takeLatest(CREATE_TEAM_BEGIN, CreateTeam),
    takeLatest(EDIT_TEAM_BEGIN, EditTeam),
    takeLatest(GET_TEAM_MEMBERS_BEGIN, GetTeamMembers),
    takeLatest(CREATE_TEAM_MEMBER_BEGIN, CreateTeamMember),
    takeLatest(DELETE_TEAM_MEMBER_BEGIN, DeleteTeamMember),
    takeLatest(GET_COMPANY_BY_ID_BEGIN, company),
    takeLatest(GET_TEAMS_BY_COMPANY_ID_BEGIN, TeamsByCompanyId),
    takeLatest(
      GET_COMPANY_RITUAL_BY_COMPANY_ID_BEGIN,
      CompanyRitualByCompanyId
    ),
    takeLatest(GET_RITUALS_BEGIN, GetRituals),
    takeLatest(CREATE_RITUAL_BEGIN, CreateRitual),
    takeLatest(UPDATE_RITUAL_BEGIN, UpdateRitual),
    takeLatest(DELETE_RITUAL_BEGIN, DeleteRitual),
    takeLatest(GET_RITUAL_ACTIONS, GetRitualActions),
    takeLatest(GET_RITUAL_TRIGGERS, GetRitualTriggers),
    takeLatest(LOGIN_FROM_EXTERNAL, ExchangeToken),
    takeLatest(GET_TEAM_RITUALS_REPORT_BEGIN, GetTeamRitualsReport),
    takeLatest(GET_TEAM_RITUALS_OVERVIEW_BEGIN, GetTeamRitualsOverview),
    takeLatest(GET_EMBED_TOKEN_BEGIN, GetEmbedToken),
  ]);
}
