import { Results } from "@groov/ui";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { AnalyticsAction } from "../../services/analytics/analyticsAction";
import AnalyticsHelper from "../../services/analytics/analyticsHelper";
import { AnalyticsObjectType } from "../../services/analytics/analyticsObjectType";
import theme from "../../styling/theme";
import { TeamRitualsReport } from "../../types/TeamRitualsReport";
import { constructRitualReportUrl } from "../../utils/constructRitualReportUrl";

interface Props {
  teamRitualsReport: TeamRitualsReport[];
}
interface ParamTypes {
  companyId: string;
  teamId: string;
}

export const TeamReport: React.FC<Props> = ({ teamRitualsReport }) => {
  const history = useHistory();

  const { companyId, teamId } = useParams<ParamTypes>();

  const onClick = (ritualId: string) => {
    const link = constructRitualReportUrl(companyId, teamId, ritualId);

    AnalyticsHelper.getInstance().trackEvent(
      "TeamReportingPage",
      AnalyticsObjectType.button,
      AnalyticsAction.clicked,
      { link: link, text: "View ritual details" }
    );

    history.push(link);
  };

  return (
    <>
      {teamRitualsReport.map((report) => {
        return (
          <Grid
            item
            xs={12}
            key={report.id}
            style={{ marginTop: theme.spacing(3) }}
          >
            <Results
              results={report}
              details={{
                onClick: () => onClick(report.id),
              }}
            />
          </Grid>
        );
      })}
    </>
  );
};
