import { useEffect, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { makeStyles } from "@material-ui/styles";
import { colors } from "../../styling/styles/colors";
import theme from "../../styling/theme";
import { CHECKIN_FREQUENCY } from "../../types/CheckinFrequency";
import { Ritual } from "../../types/Ritual";
import BasicModal from "../BasicModal";
import { FullSelectMenu } from "../Menu";
import { Input } from "../TextInput";
import { ToasterUtils } from "../Toaster";
import { updateRitual } from "../../store/actions/actions";
import { GeneralTestId } from "../../test/constants/generalTestId";
import { CreateANewRitualPageTestId } from "../../test/constants/createANewRitualPageTestId";
import RemainingCharactersCount from "../RemainingCharactersCount";
import { MAX_CONTENT_LENGTH } from "../../constants/rituals";

interface Props {
  ritualId?: string;
  open: boolean;
  onClose: () => void;
  onCommit: () => void;
}

const useStyles = makeStyles((_) => ({
  button: {
    marginTop: theme.spacing(10),
  },
  iconButtonContainer: {
    display: "flex",
    color: colors.groovBlue[100],
    marginTop: theme.spacing(6),
  },
  link: {
    padding: 0,
    margin: 0,
    color: colors.royalBlue,
  },
  description: { marginBottom: theme.spacing(6) },
  linkButton: {
    margin: theme.spacing(1),
  },
  inputRowText: {
    minWidth: "fit-content",
  },
  labelSpacing: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
}));

const EditRitualModal: React.FC<Props> = ({
  open,
  onClose,
  ritualId,
  onCommit,
}: Props) => {
  if (!open) return null;
  const ritualsRoot = useSelector((state: RootStateOrAny) => state.rituals);
  const ritual = ritualsRoot?.data?.rituals?.find(
    (r: Ritual) => r.id === ritualId
  );

  const classes = useStyles();
  const dispatch = useDispatch();

  const [trigger, setTrigger] = useState(ritual?.trigger || "");
  const [action, setAction] = useState(ritual?.action || "");
  const [checkinFrequency, setCheckinFrequency] = useState(
    ritual?.checkinFrequency || CHECKIN_FREQUENCY.EVERY_MONTH
  );
  const guideText = "Update this ritual to make it work better for your team";
  const title = "Edit ritual";
  const primaryActionTitle = "Commit";

  useEffect(() => {
    if (ritual) {
      setTrigger(ritual?.trigger);
      setAction(ritual?.action);
      setCheckinFrequency(ritual?.checkinFrequency);
    }
  }, [ritual?.trigger, ritual?.action, ritual?.checkinFrequency]);

  const onSaveClicked = (): void => {
    const data = {
      action,
      trigger,
      checkinFrequency,
    };
    if (!data.action || !data.trigger) {
      ToasterUtils.error(`Action or trigger can't be empty!!`);
      return;
    }

    dispatch(updateRitual(data, ritualId, onClose));
    onCommit();
    onClose();
  };

  const onTriggerChanged = (value: string) => {
    if (value.length <= MAX_CONTENT_LENGTH) {
      setTrigger(value);
    }
  };

  const onActionChanged = (value: string) => {
    if (value.length <= MAX_CONTENT_LENGTH) {
      setAction(value);
    }
  };

  return (
    <BasicModal
      open={open}
      title={title}
      primaryActionTitle={primaryActionTitle}
      secondaryActionTitle="Cancel"
      onClose={onClose}
      modalSize="sm"
      modalStyle="blue"
      primaryActionClick={onSaveClicked}
      secondaryActionClick={onClose}
      primaryActionLoading={ritualsRoot?.loading}
    >
      <Typography
        variant="body1"
        className={classes.inputRowText}
        data-testid={GeneralTestId.AddorEditRitualGuideText}
      >
        {guideText}
      </Typography>
      <Link
        to={{ pathname: "https://www.embed.groovnow.com/public-ritual-ideas" }}
        target="_blank"
        style={{ textDecoration: "none" }}
      >
        <Box className={classes.iconButtonContainer}>
          <EmojiObjectsOutlinedIcon />
          <Typography variant="h5">Ideas for rituals</Typography>
        </Box>
      </Link>
      <Typography
        variant="h5"
        className={`${classes.inputRowText} ${classes.labelSpacing}`}
      >
        Trigger
      </Typography>
      <Input
        data-testid={CreateANewRitualPageTestId.TriggerTextBox}
        fullWidth={true}
        name="triggers"
        value={trigger}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onTriggerChanged(e.target.value)
        }
        style={{ marginTop: 0 }}
        placeholder="Example: At the beginning of every meeting"
        multiline={true}
        minRows={3}
        maxRows={3}
      />
      <div
        style={{
          textAlign: "right",
        }}
      >
        <RemainingCharactersCount
          stringInput={trigger}
          maxLength={MAX_CONTENT_LENGTH}
        />
      </div>
      <Typography
        variant="h5"
        className={`${classes.inputRowText} ${classes.labelSpacing}`}
      >
        Action
      </Typography>
      <Input
        data-testid={CreateANewRitualPageTestId.ActionTextBox}
        fullWidth={true}
        name="actions"
        value={action}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onActionChanged(e.target.value)
        }
        style={{ marginTop: 0 }}
        placeholder="Example: Share one thing you did well, one thing you learned, and one thing you want to improve"
        multiline={true}
        minRows={3}
        maxRows={3}
      />
      <div
        style={{
          textAlign: "right",
        }}
      >
        <RemainingCharactersCount
          stringInput={action}
          maxLength={MAX_CONTENT_LENGTH}
        />
      </div>

      <Typography
        variant="h5"
        className={`${classes.inputRowText} ${classes.labelSpacing}`}
      >
        Check-in frequency
      </Typography>

      <FullSelectMenu
        data-testid={CreateANewRitualPageTestId.SelectDropDown}
        value={checkinFrequency}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setCheckinFrequency(e.target.value)
        }
        items={Object.values(CHECKIN_FREQUENCY).map((frequency) => {
          return { label: frequency, value: frequency };
        })}
      />
    </BasicModal>
  );
};

export default EditRitualModal;
