import { Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { Button, Input } from "../../../components";
import { LightTooltip } from "../../../components/LightTooltip";
import { TRIGGER_TOOLTIP, ACTION_TOOLTIP } from "../../../constants/tooltips";
import theme from "../../../styling/theme";
import InfoIcon from "@material-ui/icons/Info";
import CharacterCount from "../../../components/RemainingCharactersCount";
import { colors } from "../../../styling/styles/colors";
import AnalyticsHelper from "../../../services/analytics/analyticsHelper";
import { AnalyticsAction } from "../../../services/analytics/analyticsAction";
import PillarsSection from "./PillarsSection";
import { useEffect, useRef, useState } from "react";
import useRitualSuggestions from "../../../hooks/useRitualSuggestions";
import { RitualAction } from "../../../types/RitualAction";
import { RitualTrigger } from "../../../types/RitualTrigger";
import { ArrayRandomizer } from "../../../utils/arrayRandomizer";
import useFocus from "../../../hooks/useFocus";
import { CreateANewRitualPageTestId } from "../../../test/constants/createANewRitualPageTestId";
import { MAX_CONTENT_LENGTH } from "../../../constants/rituals";
import { AnalyticsObjectType } from "../../../services/analytics/analyticsObjectType";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    color: colors.silverSand,
    marginLeft: theme.spacing(2),
    height: "18px",
    width: "18px",
  },
  suggestionButton: {
    paddingLeft: 0,
  },
}));

const ContentTab = ({
  showContent,
  pageName,
  trigger,
  setTrigger,
  action,
  setAction,
}: {
  showContent: boolean;
  pageName: string;
  trigger: string;
  setTrigger: (trigger: string) => void;
  action: string;
  setAction: (action: string) => void;
}) => {
  const classes = useStyles();

  const [selectedPillar, setSelectedPillar] = useState("");
  const [showInputFields, setShowInputFields] = useState(false);

  const ritualActionRandomizer = useRef<ArrayRandomizer<RitualAction>>();
  const ritualTriggerRandomizer = useRef<ArrayRandomizer<RitualTrigger>>();

  const { suggestedActions, suggestedTriggers } =
    useRitualSuggestions(selectedPillar);

  const [inputRef, setInputFocus] = useFocus();

  useEffect(() => {
    ritualActionRandomizer.current = new ArrayRandomizer<RitualAction>(
      suggestedActions
    );
    ritualTriggerRandomizer.current = new ArrayRandomizer<RitualTrigger>(
      suggestedTriggers
    );

    if (selectedPillar) {
      setRandomSuggestions();
    }
  }, [JSON.stringify(suggestedActions), JSON.stringify(suggestedTriggers)]);

  const onPillarClicked = (pillar: string): void => {
    AnalyticsHelper.getInstance().trackEvent(
      pageName,
      AnalyticsObjectType.button,
      AnalyticsAction.clicked,
      { name: pillar || "Create your own" }
    );
    setShowInputFields(true);
    setSelectedPillar(pillar);
    if (pillar && pillar !== selectedPillar) setRandomSuggestions();
    setInputFocus();
  };

  const setRandomSuggestions = () => {
    if (ritualActionRandomizer?.current && ritualTriggerRandomizer?.current) {
      setAction(ritualActionRandomizer.current.getNextItem()?.action || "");
      setTrigger(ritualTriggerRandomizer.current.getNextItem()?.trigger || "");
    }
  };

  const onSuggestActionClicked = () => {
    AnalyticsHelper.getInstance().trackEvent(
      pageName,
      AnalyticsObjectType.button,
      AnalyticsAction.clicked,
      { name: "suggestAction" }
    );

    const suggestedAction = ritualActionRandomizer.current?.getNextItem();
    setAction(suggestedAction?.action || "");
  };

  const onSuggestTriggerClicked = () => {
    AnalyticsHelper.getInstance().trackEvent(
      pageName,
      AnalyticsObjectType.button,
      AnalyticsAction.clicked,
      { name: "suggestTrigger" }
    );

    const suggestedTrigger = ritualTriggerRandomizer.current?.getNextItem();
    setTrigger(suggestedTrigger?.trigger || "");
  };

  const trackFocusEvent = (element: string) => {
    AnalyticsHelper.getInstance().trackEvent(
      pageName,
      AnalyticsObjectType.input,
      AnalyticsAction.focused,
      {
        name: element,
      }
    );
  };

  const onActionChanged = (value: string) => {
    if (value.length <= MAX_CONTENT_LENGTH) {
      setAction(value);
    }
  };

  const onTriggerChanged = (value: string) => {
    if (value.length <= MAX_CONTENT_LENGTH) {
      setTrigger(value);
    }
  };

  if (!showContent) return <></>;

  return (
    <Grid container direction="column" justifyContent="center">
      <PillarsSection
        onPillarClicked={onPillarClicked}
        selectedPillar={selectedPillar}
      />
      {showInputFields && (
        <>
          <Grid
            container
            style={{
              marginBottom: theme.spacing(8),
            }}
          >
            <Grid
              item
              xs={12}
              style={{
                borderBottom: `1px solid ${colors.groovGrey[20]}`,
              }}
            />
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: theme.spacing(4),
                  }}
                >
                  Trigger
                  <LightTooltip title={TRIGGER_TOOLTIP} placement="top">
                    <InfoIcon className={classes.tooltip} />
                  </LightTooltip>
                </Typography>
                <Input
                  data-testid={CreateANewRitualPageTestId.TriggerTextBox}
                  fullWidth={true}
                  name="triggers"
                  value={trigger}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onTriggerChanged(e.target.value)
                  }
                  onFocus={() => trackFocusEvent("triggerAction")}
                  style={{ marginTop: 0 }}
                  error={trigger.length === MAX_CONTENT_LENGTH}
                  placeholder="Example: At the beginning of every meeting"
                  multiline={true}
                  rows={3}
                  maxRows={3}
                  inputRef={inputRef}
                  autoFocus
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={onSuggestTriggerClicked}
                    disableRipple
                    className={classes.suggestionButton}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                      Suggest a different one
                    </Typography>
                  </Button>
                  <CharacterCount
                    stringInput={trigger}
                    maxLength={MAX_CONTENT_LENGTH}
                  />
                </Box>
              </Grid>
              <Grid
                container
                item
                xs={1}
                direction="column"
                alignItems="center"
              >
                <Typography variant="h5">{`>>`}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="h5"
                  style={{
                    marginBottom: theme.spacing(4),
                  }}
                >
                  Action
                  <LightTooltip title={ACTION_TOOLTIP} placement="top">
                    <InfoIcon className={classes.tooltip} />
                  </LightTooltip>
                </Typography>
                <Input
                  data-testid={CreateANewRitualPageTestId.ActionTextBox}
                  fullWidth={true}
                  name="actions"
                  value={action}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onActionChanged(e.target.value)
                  }
                  onFocus={() => trackFocusEvent("triggerAction")}
                  error={action.length >= MAX_CONTENT_LENGTH}
                  style={{ marginTop: 0 }}
                  placeholder="Example: Share one thing you did well, one thing you learned, and one thing you want to improve"
                  multiline={true}
                  rows={3}
                  maxRows={3}
                />
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button
                    onClick={onSuggestActionClicked}
                    disableRipple
                    className={classes.suggestionButton}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
                      Suggest a different one
                    </Typography>
                  </Button>
                  <CharacterCount
                    stringInput={action}
                    maxLength={MAX_CONTENT_LENGTH}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ContentTab;
