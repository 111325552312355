import { SubscriptionStatus } from "../../types/SubscriptionStatus";
import { parseAccessToken } from "../../utils/parseAccessToken";
import {
  CLOSE_AUTH_MODAL,
  DELETE_USER_ACCESS_TOKEN,
  OPEN_AUTH_MODAL,
  UPDATE_CLIENT_ACCESS_TOKEN,
  UPDATE_USER_ACCESS_TOKEN,
} from "../actions/actions";

export interface AuthState {
  clientAccessToken: string;
  userAccessToken: string;
  isAuthenticated: boolean;
  userId: string;
  isWorkplaceUser: boolean;
  showAuthUI: boolean;
}

const initialState: AuthState = {
  clientAccessToken: "",
  userAccessToken: "",
  isAuthenticated: true,
  userId: "",
  isWorkplaceUser: false,
  showAuthUI: false,
};

const AuthReducer = (state: AuthState = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_CLIENT_ACCESS_TOKEN:
      return {
        ...state,
        clientAccessToken: action.payload.accessToken,
      };
    case UPDATE_USER_ACCESS_TOKEN: {
      const { sub, sub_status } = parseAccessToken(action.payload.accessToken);
      const isWorkplaceUser =
        sub_status === SubscriptionStatus.LINKED ||
        sub_status === SubscriptionStatus.GRACE_PERIOD;
      return {
        ...state,
        isWorkplaceUser,
        userAccessToken: action.payload.accessToken,
        isAuthenticated: true,
        userId: sub,
      };
    }
    case DELETE_USER_ACCESS_TOKEN: {
      return {
        ...state,
        userAccessToken: "",
        isAuthenticated: false,
        userId: "",
        isWorkplaceUser: false,
      };
    }
    case CLOSE_AUTH_MODAL: {
      return {
        ...state,
        showAuthUI: false,
      };
    }
    case OPEN_AUTH_MODAL: {
      return {
        ...state,
        showAuthUI: true,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
