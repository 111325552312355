export enum RoutPath {
  CompanyRituals = "company_rituals",
  TeamRituals = "teams",
}

export enum MainRoutPath {
  CompanyRituals = "/manage/companyrituals",
  TeamRituals = "/manage/teamrituals",
}

export enum RouteLabel {
  CompanyRituals = "Company rituals",
  TeamRituals = "Team rituals",
}

export enum RouteType {
  Public = "Public",
  Private = "Private",
}
